<template>
  <div class="message-box">
    <img src="assets/img/logo.png" alt="profile image" />
    <div class="message-content">
      <div class="message-header">
        <div class="name">Mã: {{ order.id }}</div>
        <div class="star-checkbox">
          <input type="checkbox" id="star-1" />
          <label for="star-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-star"
            >
              <polygon
                points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
              />
            </svg>
          </label>
        </div>
      </div>
      <span class="font-size-bold">Thông tin người nhận</span>
      <div class="message-line">
        <ul>
          <li
            v-for="[key, value] of Object.entries(order.buyerInfo)"
            :key="key"
          >
            {{ key }} : {{ value }}
          </li>
        </ul>
      </div>
      <span class="font-size-bold">Đơn Hàng:</span>
      <div class="message-line">
        <ul>
          <li v-for="infor in Object.values(order.products)" :key="infor">
            <span>Mã : {{ infor.id }} </span><br />
            <span>Số Lượng: {{ infor.count }} </span>
          </li>
        </ul>
      </div>
      <p class="message-line time">Dec, 12</p>
    </div>
  </div>
</template>

<script setup>
import { sample } from "lodash/collection";
defineProps(["order"]);
const randomColorCardProduct = () => {
  const colors = ["#fee4cb", "#e9e7fd", "#ffd3e2", "#c8f7dc", "#d5deff"];
  return {
    "background-color": sample(colors),
  };
};
randomColorCardProduct();
</script>

<style scoped></style>
