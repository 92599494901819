<template>
  <div class="cart-container">
    <div class="Header">
      <h5 class="Action" @click="removeAllProducts">Remove all</h5>
    </div>

    <div class="list-product">
      <transition-group name="slide">
        <div
          class="Cart-Items"
          v-for="product in state.products"
          v-bind:key="product.id"
        >
          <div class="image-box">
            <img :src="product.url" />
          </div>
          <div class="about">
            <h1 class="title">{{ product.name }}</h1>
          </div>
          <div class="counter">
            <div class="btn" @click="product.count >= 0 && product.count++">
              +
            </div>
            <div class="count">{{ product.count }}</div>
            <div class="btn" @click="product.count > 0 && product.count--">
              -
            </div>
          </div>
          <div class="prices">
            <div class="amount">
              {{
                product.price.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}
            </div>
            <div @click="removeProduct(product.id)" class="remove">
              <u>Remove</u>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script setup>
import { reactive, watch } from "vue";
import { remove } from "lodash";
import { useShop } from "@/composables/useShop";
const _shop = useShop();
const state = reactive({
  products: _shop.mappingProducts(),
  total: 0,
});
const emit = defineEmits(["on-change-price"]);

watch(
  () => state.products,
  () => {
    totalMoneyCart();
  },
  { deep: true }
);
const totalMoneyCart = () => {
  let total = 0;
  state.products.forEach((x) => {
    total = total + x.price * x.count;
  });

  state.total = total;
};
const removeProduct = (id) => {
  remove(state.products, (x) => x.id === id);
  if (state.products.length === 0) {
    _shop.clearCart();
    window.$bus.emit("on-click-outside");
  } else {
    localStorage.cartProducts = JSON.stringify(state.products);
  }
};
const removeAllProducts = () => {
  remove(state.products, () => true);
  if (state.products.length === 0) {
    _shop.clearCart();
    window.$bus.emit("on-click-outside");
  } else {
    localStorage.cartProducts = JSON.stringify(state.products);
  }
};
window.$bus.on("on-localstorage-products-change", () => {
  state.products = _shop.mappingProducts();
});
const getProductsInCart = () => {
  return state.products;
};
watch(
  () => state.total,
  (price) => {
    emit("on-change-price", price);
  }
);
totalMoneyCart();
defineExpose({
  getProductsInCart,
});
</script>
<style scoped lang="scss">
.cart-container {
  height: 100%;

  padding: 16px;
  display: flex;
  flex-direction: column;

  .list-product {
    height: 75%;
    overflow-y: auto;
    padding: 10px 0;

    .Cart-Items {
      padding: 0px 30px;
      background-color: #ffffff;
      border-radius: 20px;
      border: 1px solid #dadce0;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
    }
  }
}

.Header {
  padding-bottom: 5px;
  width: 90%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .Heading {
    font-size: 20px;
    font-family: "Open Sans", serif;
    font-weight: 700;
    color: #2f3841;
  }

  .Action {
    font-size: 14px;
    margin-top: 5px;
    font-family: "Open Sans";
    font-weight: 600;
    color: #4ecdc4;
    cursor: pointer;
    border-bottom: 1px solid #4ecdc4;
    height: fit-content;
  }
}

.image-box {
  width: 15%;
  text-align: center;

  img {
    width: -webkit-fill-available;
  }
}

.about {
  height: 100%;
  width: 24%;
}

.title {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 800;
  color: #202020;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  line-height: 10px;
  font-size: 18px;
  font-family: "Open Sans";
  font-weight: 600;
  color: #909090;
}

.counter {
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  height: 40px;
  float: right;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans";
  font-weight: 600;
  color: #555;
}

.count {
  font-size: 20px;
  font-family: "Open Sans";
  font-weight: 600;
  color: #4ecdc4;
}

.prices {
  height: 100%;
  text-align: right;
}

.amount {
  padding-top: 20px;
  font-size: 26px;
  font-family: "Open Sans";
  font-weight: 800;
  color: #202020;
}

.save {
  padding-top: 5px;
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 600;
  color: #1687d9;
  cursor: pointer;
}

.remove {
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 600;
  color: #4ecdc4;
  cursor: pointer;
}

.pad {
  margin-top: 5px;
}

hr {
  width: 66%;
  float: right;
  margin-right: 5%;
}

.checkout {
  width: 100%;
  margin: auto;
  display: flex;
  padding: 16px 32px;
  border-top: 1px solid #dadce0;

  .total {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }
}
</style>
