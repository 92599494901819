<template>
  <div>
    <!--    <div id="preloader"></div>-->
    <div
      v-show="state.showOverlay"
      class="overlay-main"
      @click="onClickOverlay"
    ></div>
    <!-- ======= Mobile nav toggle button ======= -->
    <!-- <button type="button" class="mobile-nav-toggle d-xl-none"><i class="bi bi-list mobile-nav-toggle"></i></button> -->
    <i class="bi bi-list mobile-nav-toggle d-lg-none"></i>
    <!-- ======= Header ======= -->
    <header id="header" class="d-flex flex-column justify-content-center">
      <nav id="navbar" class="navbar nav-menu">
        <ul>
          <li>
            <a href="#hero" class="nav-link scrollto active"
              ><i class="bx bx-home"></i> <span>Trang Chủ</span></a
            >
          </li>
          <li>
            <a href="#services" class="nav-link scrollto"
              ><i class="bx bx-server"></i> <span>FRAG-BOX</span></a
            >
          </li>

          <li v-show="(state.allProducts[PRODUCT_TYPE.GEAR] || []).length">
            <a href="#gear" class="nav-link scrollto"
              ><i class="bx bx-book-content"></i> <span>Phần cứng</span></a
            >
          </li>
          <li v-show="(state.allProducts[PRODUCT_TYPE.CORAL] || []).length">
            <a href="#coral" class="nav-link scrollto"
              ><i class="bx bx-book-content"></i> <span>San hô</span></a
            >
          </li>
          <li>
            <a href="#contact" class="nav-link scrollto"
              ><i class="bx bx-envelope"></i> <span>Liên Hệ</span></a
            >
          </li>
        </ul>
      </nav>

      <!-- .nav-menu -->
    </header>
    <!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <section
      id="hero"
      class="lazy d-flex flex-column justify-content-center"
      v-lazy:background-image="'assets/img/untitled-02.png'"
    >
      <div class="container" data-aos="fade-right" data-aos-delay="100">
        <h1>AI Frag Box</h1>
        <p>
          I'm
          <span
            class="typed"
            data-typed-items="Frag Box, Dosing Box, Warning Box, ATO Box"
          ></span>
        </p>
        <div class="group-btn-buy">
          <button
            class="ai-button order-frag-box-btn"
            @click="state.buyMode = BUY_MODE.AI_FRAG_BOX"
          >
            Đặt Hàng
          </button>
          <button class="ai-button buy-detail">
            <a href="frag-box" class="nav-link scrollto"
              ><span>Thông tin</span></a
            >
          </button>
        </div>
        <div class="social-links">
          <a
            href="https://www.youtube.com/@ai.reefer"
            class="youtube"
            target="_blank"
            ><i class="bx bxl-youtube"></i></a
          ><a
            href="https://www.tiktok.com/@ai.reefer"
            class="tiktok"
            target="_blank"
            ><i class="bx bxl-tiktok"></i
          ></a>
          <a
            href="https://www.instagram.com/ai.reefer/"
            class="instagram"
            target="_blank"
            ><i class="bx bxl-instagram"></i
          ></a>
          <a
            href="https://www.facebook.com/phucreefer"
            class="facebook"
            target="_blank"
            ><i class="bx bxl-facebook"></i
          ></a>
        </div>
      </div>
    </section>
    <!-- End Hero -->
    <main>
      <!-- ======= Services Section ======= -->
      <section hidden id="services" class="services">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>FRAG-BOX</h2>
            <p>
              Magnam dolores commodi suscipit. Necessitatibus eius consequatur
              ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
              quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
              Quia fugiat sit in iste officiis commodi quidem hic quas.
            </p>
          </div>

          <div class="row">
            <div
              class="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="icon-box iconbox-blue">
                <div class="icon">
                  <img
                    class="img-fluid"
                    src="https://cdn.shopify.com/s/files/1/0273/6590/0397/files/full.glass_1200x_f04f99d0-63e2-403d-bfe4-173755e232e5_1000x.jpg?v=1661868129"
                  />
                  <!-- <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                        <path stroke="none" stroke-width="0" fill="#f5f5f5" d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"></path>
                      </svg> -->
                  <!-- <i class="bx bxl-dribbble"></i> -->
                </div>
                <h4><a href="">Lorem Ipsum</a></h4>
                <p>
                  Voluptatum deleniti atque corrupti quos dolores et quas
                  molestias excepturi
                </p>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box iconbox-orange">
                <div class="icon">
                  <!-- <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                        <path stroke="none" stroke-width="0" fill="#f5f5f5" d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"></path>
                      </svg> -->
                  <img
                    class="img-fluid"
                    src="assets/img/fragbox-detail/frag-box-detail-01.png"
                  />
                </div>
                <h4><a href="">Sed Perspiciatis</a></h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore
                </p>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="icon-box iconbox-pink">
                <div class="icon">
                  <!-- <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                        <path stroke="none" stroke-width="0" fill="#f5f5f5" d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781"></path>
                      </svg> -->
                  <img
                    class="img-fluid"
                    src="assets/img/fragbox-detail/frag-box-detail-02.png"
                  />
                </div>
                <h4><a href="">Magni Dolores</a></h4>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia
                </p>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="icon-box iconbox-yellow">
                <div class="icon">
                  <!-- <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                        <path stroke="none" stroke-width="0" fill="#f5f5f5" d="M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,500.4755392045468,300,503.46388370962813"></path>
                      </svg> -->
                  <img
                    class="img-fluid"
                    src="assets/img/fragbox-detail/frag-box-detail-03.png"
                  />
                </div>
                <h4><a href="">Nemo Enim</a></h4>
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis
                </p>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box iconbox-red">
                <div class="icon">
                  <!-- <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                        <path stroke="none" stroke-width="0" fill="#f5f5f5" d="M300,532.3542879108572C369.38199826031484,532.3153073249985,429.10787420159085,491.63046689027357,474.5244479745417,439.17860296908856C522.8885846962883,383.3225815378663,569.1668002868075,314.3205725914397,550.7432151929288,242.7694973846089C532.6665558377875,172.5657663291529,456.2379748765914,142.6223662098291,390.3689995646985,112.34683881706744C326.66090330228417,83.06452184765237,258.84405631176094,53.51806209861945,193.32584062364296,78.48882559362697C121.61183558270385,105.82097193414197,62.805066853699245,167.19869350419734,48.57481801355237,242.6138429142374C34.843463184063346,315.3850353017275,76.69343916112496,383.4422959591041,125.22947124332185,439.3748458443577C170.7312796277747,491.8107796887764,230.57421082200815,532.3932930995766,300,532.3542879108572"></path>
                      </svg> -->
                  <img
                    class="img-fluid"
                    src="assets/img/fragbox-detail/frag-box-detail-04.png"
                  />
                </div>
                <h4><a href="">Dele Cardo</a></h4>
                <p>
                  Quis consequatur saepe eligendi voluptatem consequatur dolor
                  consequuntur
                </p>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="icon-box iconbox-teal">
                <div class="icon">
                  <!-- <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                        <path stroke="none" stroke-width="0" fill="#f5f5f5" d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"></path>
                      </svg> -->
                  <img
                    class="img-fluid"
                    src="assets/img/fragbox-detail/frag-box-detail-02.png"
                  />
                </div>
                <h4><a href="">Divera Don</a></h4>
                <p>
                  Modi nostrum vel laborum. Porro fugit error sit minus sapiente
                  sit aspernatur
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Services Section -->
      <div class="btn-group group-btns-shopping">
        <h2
          v-for="ctg in state.categoriesShopping"
          :class="{ active: ctg.isActive }"
          :key="ctg.id"
          @click="onChangeCategoryShopping(ctg)"
        >
          {{ ctg.name }}
        </h2>
      </div>
      <AiShop
        id="gear"
        v-if="(state.allProducts[state.currentCtgShopping.id] || []).length"
        :products="state.allProducts[state.currentCtgShopping.id] || []"
        :category="state.currentCtgShopping.id"
        :description="state.currentCtgShopping.description"
        :name="state.currentCtgShopping.name"
      ></AiShop>
      <SideRightBar
        ref="sideBarRef"
        v-if="state.sideBarInfo"
        class="slide-bar-cart"
      >
        <template v-slot:header>
          <div class="slide-bar-cart__header">
            <span>{{ state.sideBarInfo.header }}</span>
            <div class="btn-close-side-bar" @click="onCloseSideBar">
              <span>X</span>
            </div>
          </div>
        </template>
        <template v-slot:content>
          <component
            :is="state.sideBarInfo.content"
            ref="aiCartRef"
            v-on="state.sideBarInfo.events"
          ></component>
        </template>
        <template v-slot:footer>
          <div class="slide-bar-cart__footer">
            <div class="text-group" v-if="state.sideBarInfo.errorText">
              <div class="checkout">
                <div class="total">
                  <div>
                    <div class="Subtotal">
                      {{ state.sideBarInfo.errorText }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="state.sideBarInfo.data"
              class="text-group flex ml-3"
            >
              <span>Tổng tiền :</span>
              <span class="font-semibold text-lg">{{
                state.sideBarInfo.data.total.toLocaleString("it-IT", {
                  style: "currency",
                  currency: "VND",
                })
              }}</span>
            </div>
            <div class="btn-group" v-show="!state.sideBarInfo.footer.disable">
              <transition name="fade">
                <div
                  v-show="state.cartStep > 0"
                  @click="backCartStep"
                  class="button btn-back-checkout"
                >
                  <span>Back</span>
                </div>
              </transition>
              <transition name="fade">
                <div
                  @click="state.sideBarInfo.button.action"
                  class="button btn-checkout"
                >
                  <span>{{ state.sideBarInfo.button.text }}</span>
                </div>
              </transition>
            </div>
          </div>
        </template>
      </SideRightBar>
      <!-- ======= Portfolio Section ======= -->

      <!-- ======= Testimonials Section ======= -->

      <!-- End Testimonials Section -->

      <!-- ======= Contact Section ======= -->
      <section id="contact" class="contact">
        <section id="testimonials" class="testimonials section-bg">
          <div class="container" data-aos="fade-up">
            <div class="section-title">
              <h2>Thông tin về AI-REEFER</h2>
            </div>

            <div
              class="testimonials-slider swiper"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="testimonial-item">
                    <img
                      src="assets/img/testimonials/tranphuc.png"
                      class="testimonial-img"
                      alt=""
                    />
                    <h3>Trần Phúc</h3>
                    <h4>Young Men</h4>
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      Go Slow and let's it grow.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>
                <!--                &lt;!&ndash; End testimonial item &ndash;&gt;-->

                <!--                <div class="swiper-slide">-->
                <!--                  <div class="testimonial-item">-->
                <!--                    <img-->
                <!--                      src="assets/img/testimonials/testimonials-2.jpg"-->
                <!--                      class="testimonial-img"-->
                <!--                      alt=""-->
                <!--                    />-->
                <!--                    <h3>Trần Phúc</h3>-->
                <!--                    <h4>Designer</h4>-->
                <!--                    <p>-->
                <!--                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>-->
                <!--                      Export tempor illum tamen malis malis eram quae irure esse-->
                <!--                      labore quem cillum quid cillum eram malis quorum velit-->
                <!--                      fore eram velit sunt aliqua noster fugiat irure amet legam-->
                <!--                      anim culpa.-->
                <!--                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>-->
                <!--                    </p>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash; End testimonial item &ndash;&gt;-->

                <!--                <div class="swiper-slide">-->
                <!--                  <div class="testimonial-item">-->
                <!--                    <img-->
                <!--                      src="assets/img/testimonials/testimonials-3.jpg"-->
                <!--                      class="testimonial-img"-->
                <!--                      alt=""-->
                <!--                    />-->
                <!--                    <h3>Trần Phúc</h3>-->
                <!--                    <h4>Store Owner</h4>-->
                <!--                    <p>-->
                <!--                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>-->
                <!--                      Enim nisi quem export duis labore cillum quae magna enim-->
                <!--                      sint quorum nulla quem veniam duis minim tempor labore-->
                <!--                      quem eram duis noster aute amet eram fore quis sint minim.-->
                <!--                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>-->
                <!--                    </p>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash; End testimonial item &ndash;&gt;-->

                <!--                <div class="swiper-slide">-->
                <!--                  <div class="testimonial-item">-->
                <!--                    <img-->
                <!--                      src="assets/img/testimonials/testimonials-4.jpg"-->
                <!--                      class="testimonial-img"-->
                <!--                      alt=""-->
                <!--                    />-->
                <!--                    <h3>Matt Brandon</h3>-->
                <!--                    <h4>Freelancer</h4>-->
                <!--                    <p>-->
                <!--                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>-->
                <!--                      Fugiat enim eram quae cillum dolore dolor amet nulla culpa-->
                <!--                      multos export minim fugiat minim velit minim dolor enim-->
                <!--                      duis veniam ipsum anim magna sunt elit fore quem dolore-->
                <!--                      labore illum veniam.-->
                <!--                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>-->
                <!--                    </p>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash; End testimonial item &ndash;&gt;-->

                <!--                <div class="swiper-slide">-->
                <!--                  <div class="testimonial-item">-->
                <!--                    <img-->
                <!--                      src="assets/img/testimonials/testimonials-5.jpg"-->
                <!--                      class="testimonial-img"-->
                <!--                      alt=""-->
                <!--                    />-->
                <!--                    <h3>John Larson</h3>-->
                <!--                    <h4>Entrepreneur</h4>-->
                <!--                    <p>-->
                <!--                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>-->
                <!--                      Quis quorum aliqua sint quem legam fore sunt eram irure-->
                <!--                      aliqua veniam tempor noster veniam enim culpa labore duis-->
                <!--                      sunt culpa nulla illum cillum fugiat legam esse veniam-->
                <!--                      culpa fore nisi cillum quid.-->
                <!--                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>-->
                <!--                    </p>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                &lt;!&ndash; End testimonial item &ndash;&gt;-->
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </section>
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>Liên Hệ</h2>
          </div>

          <div class="row mt-1">
            <div class="col-lg-4">
              <div class="info">
                <div class="address">
                  <i class="bi bi-geo-alt"></i>
                  <h4>Địa chỉ:</h4>
                  <p>
                    01 Trường Thi 4, Hòa Thuận Tây, Hải Châu, Đà Nẵng 550000
                  </p>
                </div>

                <div class="email">
                  <i class="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>tranphuc7686@gmail.com</p>
                </div>

                <div class="phone">
                  <i class="bi bi-phone"></i>
                  <h4>Số điện thoại:</h4>
                  <p>0393337686</p>
                </div>
              </div>
            </div>

            <div class="col-lg-8 mt-5 mt-lg-0">
              <form
                action="forms/contact.php"
                method="post"
                role="form"
                class="php-email-form"
              >
                <div class="row">
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div class="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div class="form-group mt-3">
                  <input
                    type="text"
                    class="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div class="form-group mt-3">
                  <textarea
                    class="form-control"
                    name="message"
                    rows="5"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <div class="my-3">
                  <div class="loading">Loading</div>
                  <div class="error-message"></div>
                  <div class="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <!-- End Contact Section -->
    </main>
    <!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer id="footer">
      <div class="container">
        <h3>AI Frag Box</h3>
        <p>Making reef simple and easy again</p>
        <div class="social-links">
          <!--          <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>-->
          <a href="https://www.facebook.com/phucreefer" class="facebook"
            ><i class="bx bxl-facebook"></i
          ></a>
          <a href="https://www.facebook.com/phucreefer" class="youtube"
            ><i class="bx youtube"></i
          ></a>
          <!--          <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>-->
          <!--          <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>-->
          <!--          <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>-->
        </div>
        <div class="copyright">
          &copy; Copyright <strong><span>AI-REEFER</span></strong
          >. All Rights Reserved
        </div>
        <div class="credits">
          <!-- All the links in the footer should remain intact. -->
          <!-- You can delete the links only if you purchased the pro version. -->
          <!-- Licensing information: [license-url] -->
          <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/free-html-bootstrap-template-my-resume/ -->
          Designed by
          <a href="https://www.facebook.com/tranphuc.tc/">Trần Phúc</a>
        </div>
      </div>
    </footer>
    <!-- End Footer -->

    <!-- <div id="preloader"></div> -->
    <a
      href="#"
      class="right-btn back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
    <button
      v-if="state.hasProductOnCart"
      href="#"
      class="right-btn cart-btn d-flex align-items-center justify-content-center active"
      @click="state.buyMode = BUY_MODE.NORMAL"
    >
      <i class="bi bi-cart"></i>
    </button>
  </div>
</template>
<script setup>
import { reactive, onMounted, ref, computed, watch } from "vue";
import SideRightBar from "@/components/SideRightBar";

import InfoBuyer from "@/components/cart/InfoBuyer";
import OrderSuccess from "@/components/cart/OrderSuccess";
// import OrderSuccess from "@/components/cart/OrderSuccess";
import AiShop from "@/components/AIShop";
import ConfigOptionList from "@/components/cart/config-opions/ConfigOptionsList";
// import AiDashboard from "@/components/admin/AiDashboard";
import CartShop from "@/components/cart/CartShop";
import {
  CART_STEPS,
  SHOP_ITEM_TYPE,
  PRODUCT_TYPE,
  SHOPPING_CATEGORIES,
} from "@/const";

import { useShop } from "@/composables/useShop";
import { templatejs, buildGlighBox } from "../../templatejs";
import { useOrder } from "@/composables/useOrder";
import { cloneDeep, forEach } from "lodash";
const shop = useShop();
const order = useOrder();
let nextCartStep = () => {};
const STEPS_NORMAL = {
  CART: 0,
};
const buyNormal = {
  0: {
    header: "Giỏ Hàng",
    content: CartShop,
    ref: "aiCart",
    footer: {
      text: "",
      disable: false,
    },

    events: {
      "on-change-price": async function (price) {
        buyNormal[STEPS_NORMAL.CART].data.total = price;

        Object.assign(buyNormal[STEPS_NORMAL.CART], state.sideBarInfo);
      },
    },
    data: {
      total: 0,
      products: [],
    },
    button: {
      text: "Tiếp theo",
      action: () => {
        nextCartStep();
        buyNormal[STEPS_NORMAL.CART].data.products =
          aiCartRef.value.getProductsInCart();
      },
    },
  },
  1: {
    header: "Thông tin người nhận",
    content: InfoBuyer,
    ref: "cartInfo",
    footer: {
      text: "",
      disable: false,
    },
    errorText: "Thiếu thông tin người nhận",
    button: {
      text: "Xác Nhận",
      action: async () => {
        if (aiCartRef.value.isCorrectInfo()) {
          nextCartStep();
          const products = JSON.stringify(
            buyNormal[STEPS_NORMAL.CART].data.products.map((x) => {
              return {
                id: x.id,
                count: x.count,
                create_at: new Date().toLocaleString(),
              };
            })
          );
          await order.createOrder(products, aiCartRef.value.info, () => {
            shop.clearCart();
            state.hasProductOnCart = shop.hasProductOnCart();
          });
          state.hasError = false;
        } else {
          state.hasError = true;
        }
      },
    },
  },
  2: {
    header: "Cám ơn bạn đã đặt hàng",
    content: OrderSuccess,
    footer: {
      text: "",
      disable: true,
    },
    button: {
      text: "",
      action: () => {
        console.log("Xác Nhận Clicked");
      },
    },
  },
};
const STEPS = {
  CONFIGS_AI_FRAGBOX: 0,
};
const buyFragboxCart = {
  0: {
    header: "Giỏ Hàng",
    content: ConfigOptionList,
    ref: "configOptionListRef",
    events: {
      "on-config-price-change": async function (payload) {
        let _package = {
          total: 13000000 + payload.total,
          configs: payload.configs,
        };
        Object.assign(buyFragboxCart[STEPS.CONFIGS_AI_FRAGBOX].data, _package);
        Object.assign(
          buyFragboxCart[STEPS.CONFIGS_AI_FRAGBOX],
          state.sideBarInfo
        );
      },
    },
    footer: {
      text: "",
      disable: false,
    },
    data: {
      configs: "",
      total: 0,
    },
    button: {
      text: "Tiếp theo",
      action: () => {
        nextCartStep();
      },
    },
  },
  1: {
    header: "Thông tin người nhận",
    content: InfoBuyer,
    ref: "cartInfoRef",
    footer: {
      text: "",
      disable: false,
    },
    errorText: "Thiếu thông tin người nhận",
    button: {
      text: "Xác Nhận",
      action: async () => {
        if (aiCartRef.value.isCorrectInfo()) {
          nextCartStep();
          await order.createOrder(
            order.reformatConfigList(
              JSON.parse(buyFragboxCart[STEPS.CONFIGS_AI_FRAGBOX].data.configs)
            ),
            JSON.stringify(aiCartRef.value.info),
            () => {
              shop.clearCart();
              state.hasProductOnCart = shop.hasProductOnCart();
            }
          );
          state.hasError = false;
        } else {
          state.hasError = true;
        }
      },
    },
  },
  2: {
    header: "Cám ơn bạn đã đặt hàng",
    content: OrderSuccess,

    ref: "xxx",
    footer: {
      text: "",
      disable: true,
    },
    button: {
      text: "",
      action: () => {
        console.log("Xác Nhận Clicked");
      },
    },
  },
};
const BUY_MODE = {
  AI_FRAG_BOX: 2,
  NORMAL: 1,
  EMPTY: 0,
};

const state = reactive({
  showOverlay: false,
  buyMode: BUY_MODE.EMPTY,
  cartStep: CART_STEPS.ITEMS,
  buyNormal,
  buyFragboxCart,
  cartStepBuyFragBox: CART_STEPS.ITEMS,
  sideBarInfo: computed(() => {
    if (state.buyMode === BUY_MODE.EMPTY) return;
    return carts[state.buyMode][state.cartStep];
  }),
  hasProductOnCart: shop.hasProductOnCart(),
  hasError: false,
  allProducts: {},
  fragBoxTotalPrice: 0,
  categoriesShopping: cloneDeep(SHOPPING_CATEGORIES),
  currentCtgShopping: {},
});

const carts = {
  1: state.buyNormal,
  2: state.buyFragboxCart,
};
state.currentCtgShopping = state.categoriesShopping[0];
const sideBarRef = ref();
const aiCartRef = ref();
watch(
  () => state.buyMode,
  (mode) => {
    if (mode === BUY_MODE.EMPTY) return;
    toggleCart();
  }
);

const toggleCart = () => {
  setTimeout(() => {
    sideBarRef.value && sideBarRef.value.toggle();
  }, 300);
  if (state.cartStep !== CART_STEPS.ITEMS) {
    state.cartStep = CART_STEPS.ITEMS;
  }
};
nextCartStep = () => {
  state.cartStep++;
};
const backCartStep = () => {
  state.cartStep--;
};
const onClickOverlay = () => {
  window.$bus.emit("on-click-outside");
  state.buyMode = BUY_MODE.EMPTY;
};
const onCloseSideBar = () => {
  sideBarRef.value.toggle();

  state.buyMode = BUY_MODE.EMPTY;
};
const onChangeCategoryShopping = (activedCtg) => {
  forEach(state.categoriesShopping, (ctg) => {
    ctg.isActive = ctg.id === activedCtg.id;
  });
  state.currentCtgShopping = activedCtg;
};

window.$bus.on("on-show-overlay", (isShow) => {
  state.hasProductOnCart = shop.hasProductOnCart();
  state.showOverlay = isShow;
  if (isShow) {
    window.$(".right-btn").css({ opacity: 0 });
  } else {
    window.$(".right-btn").css({ opacity: 1 });
  }
});
window.$bus.on("on-localstorage-products-change", () => {
  state.hasProductOnCart = shop.hasProductOnCart();
});
shop.getProducts((products) => {
  Object.keys(SHOP_ITEM_TYPE).forEach((keyP) => {
    state.allProducts[+keyP] = products.filter((x) => x.type === +keyP);
  });
  setTimeout(() => {
    buildGlighBox();
  }, 1000);
});
onMounted(() => {
  templatejs();
});
</script>
<style lang="scss">
.lazy {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}
.lazy[lazy="loaded"] {
  opacity: 1;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay-main {
  transition: 0.5s;
  background: #000;
  height: 100vh;
  width: 100%;
  opacity: 0.6;
  position: fixed;
  z-index: 1;
}

header {
  line-height: 1.5;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }
}

#menuToggle {
  display: block;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 0;
  padding: 50px;
  padding-top: 125px;
  right: -100px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

#menuToggle input:checked ~ ul {
  transform: scale(1, 1);
  opacity: 1;
}

.cart-btn {
  padding: 30px;
  border: none;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 60px;
  z-index: 996;
  background: #4ecdc4;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;

  i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }

  &:hover {
    background: #0678e3;
    color: #fff;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }
  &.shake {
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.side-right {
  &__header {
    flex: none !important;
    padding: 16px !important;
  }
  &__content {
    margin: 0 !important;
  }
  &__footer {
    display: flex;
    padding: 8px 16px;
    .Subtotal {
      font-size: 16px;
      font-family: "Open Sans";
      font-weight: 700;
      color: red;
    }

    .items {
      font-size: 12px;
      font-family: "Open Sans";
      font-weight: 500;
      color: #909090;
      line-height: 10px;
    }

    .total-amount {
      font-size: 16px;
      font-family: "Open Sans";
      font-weight: 900;
      color: #202020;
    }
  }
}

.order-frag-box-btn {
  background: #4ecdc4;
  color: #fff;
}
.swiper {
  height: 100%;
  .swiper-wrapper {
    //margin-top: 16px;
  }
  .swiper-pagination-progressbar-fill {
    background-color: #4ecdc4 !important;
  }
}
.slide-bar-cart {
  &__header {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-close-side-bar {
      cursor: pointer;
      color: #4ecdc4;
    }
    span {
      font-size: 21px;
      font-weight: 600;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .button {
      border-radius: 8px;
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
    }
    .btn-back-checkout {
      color: #555;
      background-color: #fff;
      border: 1px solid #dadce0;
      margin-right: 5px;
      padding: 15px;
    }
    .btn-checkout {
      color: #fff;
      background-color: #4ecdc4;
    }
  }
}

.is-mobile {
  .group-btn-buy {
    justify-content: center;
  }
  .slide-bar-cart {
    &__footer {
      .text-group {
        flex-direction: column;
        margin-left: unset;
      }
      .btn-group {
        width: 100%;
      }
    }
  }
}
.group-btns-shopping {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0 0;
  column-gap: 20px;
  h2 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    color: #a9a9a9;

    transition: all 0.5s ease;
    cursor: pointer;
  }

  .active {
    color: #4ecdc4;
    font-size: 32px;
  }
}
</style>
