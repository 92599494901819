<template>
  <AiLogin v-show="!isAlreadyLogin" class="app-container" />
  <div v-show="isAlreadyLogin" class="app-container">
    <div class="app-header">
      <div class="app-header-left">
        <span class="app-icon"></span>
        <p class="app-name">Portfolio</p>
        <div class="search-wrapper">
          <input
            class="search-input"
            type="text"
            placeholder="Search"
            @input="onSearch"
            v-model="state.keyWord"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="feather feather-search"
            viewBox="0 0 24 24"
          >
            <defs></defs>
            <circle cx="11" cy="11" r="8"></circle>
            <path d="M21 21l-4.35-4.35"></path>
          </svg>
        </div>
      </div>
      <div class="app-header-right">
        <button class="mode-switch" title="Switch Theme">
          <svg
            class="moon"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <defs></defs>
            <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
          </svg>
        </button>
        <button class="add-btn" title="Add New Project">
          <svg
            class="btn-icon feather feather-plus"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
        <button class="notification-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-bell"
          >
            <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
            <path d="M13.73 21a2 2 0 0 1-3.46 0" />
          </svg>
        </button>
        <button class="profile-btn">
          <img src="https://assets.codepen.io/3306515/IMG_2025.jpg" />
          <span>Aybüke C.</span>
        </button>
      </div>
      <button class="messages-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-message-circle"
        >
          <path
            d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
          />
        </svg>
      </button>
    </div>
    <div class="app-content">
      <div class="app-sidebar">
        <a href="" class="app-sidebar-link active">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
            <polyline points="9 22 9 12 15 12 15 22" />
          </svg>
        </a>
        <a href="" class="app-sidebar-link">
          <svg
            class="link-icon feather feather-pie-chart"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
          >
            <defs />
            <path d="M21.21 15.89A10 10 0 118 2.83M22 12A10 10 0 0012 2v10z" />
          </svg>
        </a>
        <a href="" class="app-sidebar-link">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-calendar"
          >
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
            <line x1="16" y1="2" x2="16" y2="6" />
            <line x1="8" y1="2" x2="8" y2="6" />
            <line x1="3" y1="10" x2="21" y2="10" />
          </svg>
        </a>
        <a href="" class="app-sidebar-link">
          <svg
            class="link-icon feather feather-settings"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
          >
            <defs />
            <circle cx="12" cy="12" r="3" />
            <path
              d="M19.4 15a1.65 1.65 0 00.33 1.82l.06.06a2 2 0 010 2.83 2 2 0 01-2.83 0l-.06-.06a1.65 1.65 0 00-1.82-.33 1.65 1.65 0 00-1 1.51V21a2 2 0 01-2 2 2 2 0 01-2-2v-.09A1.65 1.65 0 009 19.4a1.65 1.65 0 00-1.82.33l-.06.06a2 2 0 01-2.83 0 2 2 0 010-2.83l.06-.06a1.65 1.65 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3a2 2 0 01-2-2 2 2 0 012-2h.09A1.65 1.65 0 004.6 9a1.65 1.65 0 00-.33-1.82l-.06-.06a2 2 0 010-2.83 2 2 0 012.83 0l.06.06a1.65 1.65 0 001.82.33H9a1.65 1.65 0 001-1.51V3a2 2 0 012-2 2 2 0 012 2v.09a1.65 1.65 0 001 1.51 1.65 1.65 0 001.82-.33l.06-.06a2 2 0 012.83 0 2 2 0 010 2.83l-.06.06a1.65 1.65 0 00-.33 1.82V9a1.65 1.65 0 001.51 1H21a2 2 0 012 2 2 2 0 01-2 2h-.09a1.65 1.65 0 00-1.51 1z"
            />
          </svg>
        </a>
      </div>
      <div class="projects-section">
        <div class="projects-section-header">
          <p>Sản Phẩm</p>
          <button
            @click="createNewProduct"
            class="time create-product-btn ai-button"
          >
            Thêm Sản Phẩm
          </button>
        </div>
        <div class="projects-section-line">
          <div class="projects-status">
            <div class="item-status">
              <span class="status-number">45</span>
              <span class="status-type">In Progress</span>
            </div>
            <div class="item-status">
              <span class="status-number">24</span>
              <span class="status-type">Upcoming</span>
            </div>
            <div class="item-status">
              <span class="status-number">62</span>
              <span class="status-type">Total Projects</span>
            </div>
          </div>
          <div class="view-actions">
            <button class="view-btn list-view" title="List View">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-list"
              >
                <line x1="8" y1="6" x2="21" y2="6" />
                <line x1="8" y1="12" x2="21" y2="12" />
                <line x1="8" y1="18" x2="21" y2="18" />
                <line x1="3" y1="6" x2="3.01" y2="6" />
                <line x1="3" y1="12" x2="3.01" y2="12" />
                <line x1="3" y1="18" x2="3.01" y2="18" />
              </svg>
            </button>
            <button class="view-btn grid-view active" title="Grid View">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-grid"
              >
                <rect x="3" y="3" width="7" height="7" />
                <rect x="14" y="3" width="7" height="7" />
                <rect x="14" y="14" width="7" height="7" />
                <rect x="3" y="14" width="7" height="7" />
              </svg>
            </button>
          </div>
        </div>
        <div class="project-boxes jsGridView">
          <div
            v-for="product in state.listProductsDisplay"
            :key="product.id"
            class="project-box-wrapper"
          >
            <transition name="fade">
              <AiProduct
                v-if="product"
                @on-click-update="onClickUpdate"
                @on-removed-product="onRemovedProduct"
                :product="product"
              />
            </transition>
          </div>
        </div>
      </div>
      <div class="messages-section">
        <button class="messages-close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x-circle"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </button>
        <div class="projects-section-header">
          <p>Đơn Hàng</p>
        </div>
        <div class="messages">
          <template v-for="order in state.listOrders" :key="order.id">
            <AiOrder :order="order" />
          </template>
        </div>
      </div>
    </div>
    <SideRightBar ref="sideBarRef" class="slide-bar-cart">
      <template v-slot:header>
        <div class="slide-bar-cart__header">
          <span>Thông tin sản phẩm</span>
          <div class="btn-close-side-bar" @click="onCloseSideBar">
            <span>X</span>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div>
          <label for="textInput">Nhập văn bản:</label>
          <input
            id="textInput"
            v-model="state.inputText"
            @input="extractText"
            class="custom-input"
          />
          <button @click="copyToClipboard" class="copy-button">Copy</button>

          <div>
            <p class="custom-paragraph">{{ state.extractedText }}</p>
          </div>
        </div>
        <div class="container">
          <div class="container-form">
            <form action="/action_page.php">
              <div class="row">
                <div class="col-100">
                  <input v-model="state.selectedProduct.id" type="hidden" />
                  <label for="fname"><i class="fa fa-user"></i> Tên:</label>
                  <input
                    v-model="state.selectedProduct.name"
                    type="text"
                    id="fname"
                    name="firstname"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i> Url ảnh:</label
                  >

                  <img
                    v-show="state.selectedProduct.url"
                    class="img-fluid small-picture"
                    :src="state.selectedProduct.url"
                    alt=""
                  />
                  <input
                    v-model="state.selectedProduct.url"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="john@example.com"
                  />
                  <label for="tel"
                    ><i class="fa fa-institution"></i> Giá Số
                    {{
                      Number(state.selectedProduct.price).toLocaleString(
                        "it-IT",
                        {
                          style: "currency",
                          currency: "VND",
                        }
                      )
                    }}</label
                  >
                  <input
                    v-model="state.selectedProduct.price"
                    type="number"
                    id="tel"
                    name="tel"
                    placeholder="039...."
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i> Giá Chữ</label
                  >
                  <input
                    v-model="state.selectedProduct.priceText"
                    type="text"
                    id="fname"
                    name="price_text"
                    placeholder="John M. Doe"
                  />
                  <label for="quantity"
                    ><i class="fa fa-institution"></i> Số lượng</label
                  >
                  <input
                    v-model="state.selectedProduct.quantity"
                    type="number"
                    id="quantity"
                    name="tel"
                    placeholder="039...."
                  />
                  <label for="productType"
                    ><i class="fa fa-institution"></i
                    >{{ state.selectedProduct.type }} Hình thức sản phẩm</label
                  >
                  <select
                    v-model="state.selectedProduct.type"
                    id="productType"
                    name="productType"
                  >
                    <option :value="PRODUCT_TYPE.GEAR">Phần Cứng</option>
                    <option :value="PRODUCT_TYPE.CORAL">San Hô</option>
                  </select>
                  <label for="category"
                    ><i class="fa fa-institution"></i> Loại sản phẩm</label
                  >
                  <select
                    v-if="state.selectedProduct.type === PRODUCT_TYPE.CORAL"
                    v-model="state.selectedProduct.ctg"
                    id="category"
                    name="category"
                  >
                    <option
                      v-for="ctg in CORAL_CATEGORIES"
                      :value="ctg.id"
                      :key="ctg.id"
                    >
                      {{ ctg.name }}
                    </option>
                  </select>

                  <label for="email"
                    ><i class="fa fa-envelope"></i> Chú Thích</label
                  >

                  <input
                    v-model="state.selectedProduct.details.description"
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i> Link Video</label
                  >

                  <input
                    v-model="state.selectedProduct.details.urlVideo"
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Link Ảnh</label
                  >

                  <input
                    v-model="state.selectedProduct.details.urlImage"
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <!--                  Độ Khó-->
                  <h3>Độ khó</h3>
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: Start Text</label
                  >

                  <input
                    v-model="
                      state.selectedProduct.details.levelBars[2].startText
                    "
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: End Text</label
                  >

                  <input
                    v-model="state.selectedProduct.details.levelBars[2].endText"
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: Level</label
                  >

                  <input
                    v-model="state.selectedProduct.details.levelBars[2].level"
                    type="number"
                    min="1"
                    max="5"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: Description</label
                  >

                  <input
                    v-model="
                      state.selectedProduct.details.levelBars[2].description
                    "
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <!--                  FLow-->
                  <h3>Flow</h3>
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: Start Text</label
                  >

                  <input
                    v-model="
                      state.selectedProduct.details.levelBars[0].startText
                    "
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: End Text</label
                  >

                  <input
                    v-model="state.selectedProduct.details.levelBars[0].endText"
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: Level</label
                  >

                  <input
                    v-model="state.selectedProduct.details.levelBars[0].level"
                    type="number"
                    min="1"
                    max="5"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: Description</label
                  >

                  <input
                    v-model="
                      state.selectedProduct.details.levelBars[0].description
                    "
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <!--                  Light-->

                  <h3>Light</h3>
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: Start Text</label
                  >

                  <input
                    v-model="
                      state.selectedProduct.details.levelBars[1].startText
                    "
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: End Text</label
                  >

                  <input
                    v-model="state.selectedProduct.details.levelBars[1].endText"
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: Level</label
                  >

                  <input
                    v-model="state.selectedProduct.details.levelBars[1].level"
                    type="number"
                    min="1"
                    max="5"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                  <label for="email"
                    ><i class="fa fa-envelope"></i>Levelbar: Description</label
                  >

                  <input
                    v-model="
                      state.selectedProduct.details.levelBars[1].description
                    "
                    type="text"
                    id="fname"
                    name="details"
                    placeholder="John M. Doe"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div
          style="float: right"
          @click="onUpdateProduct"
          class="button btn-checkout"
        >
          <span v-if="state.selectedProduct.id">Cập nhật</span>
          <span v-else>Tạo mới</span>
        </div>
      </template>
    </SideRightBar>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useShop } from "@/composables/useShop";
import { useOrder } from "@/composables/useOrder";
import AiProduct from "@/components/admin/AiProduct";
import AiOrder from "@/components/admin/AiOrder";
import SideRightBar from "@/components/SideRightBar";
import { remove } from "lodash";
import { PRODUCT_TYPE, CORAL_CATEGORIES } from "@/const";
import AiLogin from "@/components/admin/AiLogin";

const initUI = () => {
  var modeSwitch = document.querySelector(".mode-switch");

  modeSwitch.addEventListener("click", function () {
    document.documentElement.classList.toggle("dark");
    modeSwitch.classList.toggle("active");
  });

  var listView = document.querySelector(".list-view");
  var gridView = document.querySelector(".grid-view");
  var projectsList = document.querySelector(".project-boxes");

  listView.addEventListener("click", function () {
    gridView.classList.remove("active");
    listView.classList.add("active");
    projectsList.classList.remove("jsGridView");
    projectsList.classList.add("jsListView");
  });

  gridView.addEventListener("click", function () {
    gridView.classList.add("active");
    listView.classList.remove("active");
    projectsList.classList.remove("jsListView");
    projectsList.classList.add("jsGridView");
  });

  document
    .querySelector(".messages-btn")
    .addEventListener("click", function () {
      document.querySelector(".messages-section").classList.add("show");
    });

  document
    .querySelector(".messages-close")
    .addEventListener("click", function () {
      document.querySelector(".messages-section").classList.remove("show");
    });
};
const shop = useShop();
const order = useOrder();
shop.getProducts((result) => {
  state.listProducts = result;
});
shop.getOrders(
  (result) => {
    state.listOrders = result;
  },
  (error) => {
    if (error.response.status === 403) {
      isAlreadyLogin.value = false;
    }
  }
);
const isAlreadyLogin = ref(true);
const sideBarRef = ref(null);
const state = reactive({
  inputText: "",
  extractedText: "",
  listProducts: [],
  listProductsDisplay: computed({
    get: () => {
      return state.listProducts.filter((x) => x.id.includes(state.keyWord));
    },
    set: () => {},
  }),
  listOrders: [],
  selectedProduct: {
    id: 0,
    url: "",
    name: "",
    type: 0,
    ctg: 1,
    price: 0,
    priceText: "",
    quantity: 0,
    details: {
      urlVideo: "",
      description: "",
      urlImage: "",
      levelBars: [
        {
          startText: "",
          endText: "",
          description: "",
          level: 0,
        },
        {
          startText: "",
          endText: "",
          description: "",
          level: 0,
        },
        {
          startText: "",
          endText: "",
          description: "",
          level: 0,
        },
      ],
    },
  },
  keyWord: "",
});
const extractRawUrl = (url) => {
  //eslint-disable-next-line
  const regex = /\/file\/d\/([^\/]+)/;
  const match = url.match(regex);

  if (match) {
    return match[1];
  }

  return url;
};
const copyToClipboard = () => {
  // Tạo một phần tử input tạm thời để chứa nội dung cần sao chép
  const tempInput = document.createElement("input");
  tempInput.value = state.extractedText;

  // Thêm phần tử vào DOM
  document.body.appendChild(tempInput);

  // Chọn nội dung của input
  tempInput.select();
  tempInput.setSelectionRange(0, 99999); // For mobile devices

  // Sao chép nội dung vào clipboard
  document.execCommand("copy");

  // Xóa phần tử tạm thời
  document.body.removeChild(tempInput);
};
const extractText = () => {
  state.extractedText =
    `https://lh3.googleusercontent.com/d/` + extractRawUrl(state.inputText);
};
const onClickUpdate = (product) => {
  if (!product.details.levelBars) {
    product.details.levelBars = [
      {
        startText: "",
        endText: "",
        description: "",
        level: 0,
      },
      {
        startText: "",
        endText: "",
        description: "",
        level: 0,
      },
      {
        startText: "",
        endText: "",
        description: "",
        level: 0,
      },
    ];
  }
  state.selectedProduct = product;
  sideBarRef.value.toggle();
};
const onRemovedProduct = (product) => {
  remove(state.listProducts, (x) => x.id === product.id);
};
const createNewProduct = () => {
  sideBarRef.value.toggle();
  state.selectedProduct = {
    id: undefined,
    url: "",
    name: "",
    type: 0,
    ctg: 1,
    price: 0,
    quantity: 0,
    details: {
      urlVideo: "",
      description: "",
      urlImage: "",
      levelBars: [
        {
          startText: "",
          endText: "",
          description: "",
          level: 0,
        },
        {
          startText: "",
          endText: "",
          description: "",
          level: 0,
        },
        {
          startText: "",
          endText: "",
          description: "",
          level: 0,
        },
      ],
    },
  };
};
const onCloseSideBar = () => {
  sideBarRef.value.toggle();
};
const onSearch = () => {};
const onUpdateProduct = async () => {
  await order.createProduct(state.selectedProduct, () => {
    sideBarRef.value.toggle();
  });
};
onMounted(() => {
  initUI();
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

* {
  box-sizing: border-box;
}
.custom-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  transition: border-color 0.3s ease;
}

.custom-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Thêm animation nếu cần thiết */
.custom-input::placeholder {
  color: #aaa;
}
.custom-paragraph {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
  line-height: 1.5;
}

.app-container {
  --app-container: #f3f6fd;
  --main-color: #1f1c2e;
  --secondary-color: #4a4a4a;
  --link-color: #1f1c2e;
  --link-color-hover: #c3cff4;
  --link-color-active: #fff;
  --link-color-active-bg: #1f1c2e;
  --projects-section: #fff;
  --message-box-hover: #fafcff;
  --message-box-border: #e9ebf0;
  --more-list-bg: #fff;
  --more-list-bg-hover: #f6fbff;
  --more-list-shadow: rgba(209, 209, 209, 0.4);
  --button-bg: #1f1c24;
  --search-area-bg: #fff;
  --star: #1ff1c2e;
  --message-btn: #fff;
}

.dark .app-container {
  --app-container: #1f1d2b;
  --app-container: #111827;
  --main-color: #fff;
  --secondary-color: rgba(255, 255, 255, 0.8);
  --projects-section: #1f2937;
  --link-color: rgba(255, 255, 255, 0.8);
  --link-color-hover: rgba(195, 207, 244, 0.1);
  --link-color-active-bg: rgba(195, 207, 244, 0.2);
  --button-bg: #1f2937;
  --search-area-bg: #1f2937;
  --message-box-hover: #243244;
  --message-box-border: rgba(255, 255, 255, 0.1);
  --star: #ffd92c;
  --light-font: rgba(255, 255, 255, 0.8);
  --more-list-bg: #2f3142;
  --more-list-bg-hover: rgba(195, 207, 244, 0.1);
  --more-list-shadow: rgba(195, 207, 244, 0.1);
  --message-btn: rgba(195, 207, 244, 0.1);
}

button,
a {
  cursor: pointer;
}

.app {
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--app-container);
    transition: 0.2s;

    button,
    input,
    optgroup,
    select,
    textarea {
      font-family: "DM Sans", sans-serif;
    }
  }

  &-content {
    display: flex;
    height: 100%;
    overflow: hidden;
    padding: 16px 24px 24px 0;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 24px;
    position: relative;

    &-left,
    &-right {
      display: flex;
      align-items: center;
    }

    &-left {
      flex-grow: 1;
    }

    &-right button {
      margin-left: 10px;
    }
  }

  &-icon {
    width: 26px;
    height: 2px;
    border-radius: 4px;
    background-color: var(--main-color);
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 12px;
      height: 2px;
      border-radius: 4px;
      background-color: var(--main-color);
      left: 50%;
      transform: translatex(-50%);
    }

    &:before {
      top: -6px;
    }
    &:after {
      bottom: -6px;
    }
  }

  &-name {
    color: var(--main-color);
    margin: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 32px;
  }
}

.mode-switch {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-wrapper {
  border-radius: 20px;
  background-color: var(--search-area-bg);
  padding-right: 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 480px;
  color: var(--light-font);
  box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2),
    0 24px 20px -24px rgba(71, 82, 107, 0.1);
  overflow: hidden;

  .dark & {
    box-shadow: none;
  }
}

.search-input {
  border: none;
  flex: 1;
  outline: none;
  height: 100%;
  padding: 0 20px;
  font-size: 16px;
  background-color: var(--search-area-bg);
  color: var(--main-color);

  &::placeholder {
    color: var(--main-color);
    opacity: 0.6;
  }
}

.add-btn {
  color: #fff;
  background-color: var(--button-bg);
  padding: 0;
  border: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-btn {
  color: var(--main-color);
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-btn {
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-left: 2px solid #ddd;

  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 4px;
  }

  span {
    color: var(--main-color);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
}

.page-content {
  flex: 1;
  width: 100%;
}

.app-sidebar {
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-link {
    color: var(--main-color);
    color: var(--link-color);
    margin: 16px 0;
    transition: 0.2s;
    border-radius: 50%;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--link-color-hover);
      color: var(--link-color-active);
    }

    &.active {
      background-color: var(--link-color-active-bg);
      color: var(--link-color-active);
    }
  }
}

.projects-section {
  flex: 2;
  background-color: var(--projects-section);
  border-radius: 32px;
  padding: 32px 32px 0 32px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  &-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    color: var(--main-color);
    .create-product-btn {
      color: var(--main-color);
    }

    p {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      opacity: 0.9;
      margin: 0;
      color: var(--main-color);
    }

    .time {
      font-size: 20px;
    }
  }
}

.projects-status {
  display: flex;
}

.item-status {
  display: flex;
  flex-direction: column;
  margin-right: 16px;

  &:not(:last-child) .status-type:after {
    content: "";
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translatey(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid var(--secondary-color);
  }
}

.status-number {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: var(--main-color);
}

.status-type {
  position: relative;
  padding-right: 24px;
  color: var(--secondary-color);
}

.view-actions {
  display: flex;
  align-items: center;
}

.view-btn {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  margin-left: 8px;
  transition: 0.2s;

  &.active {
    background-color: var(--link-color-active-bg);
    color: var(--link-color-active);
  }

  &:not(.active):hover {
    background-color: var(--link-color-hover);
    color: var(--link-color-active);
  }
}

.messages-section {
  flex-shrink: 0;
  padding-bottom: 32px;
  background-color: var(--projects-section);
  margin-left: 24px;
  flex: 1;
  width: 100%;
  border-radius: 30px;
  position: relative;
  overflow: auto;
  transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);

  .messages-close {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 3;
    border: none;
    background-color: transparent;
    color: var(--main-color);
    display: none;
  }

  &.show {
    transform: translateX(0);
    opacity: 1;
    margin-left: 0;
  }

  .projects-section-header {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 32px 24px 0 24px;
    background-color: var(--projects-section);
  }
}

.message-box {
  border-top: 1px solid var(--message-box-border);
  padding: 16px;
  display: flex;
  align-items: flex-start;
  width: 100%;

  &:hover {
    background-color: var(--message-box-hover);
    border-top-color: var(--link-color-hover);

    + .message-box {
      border-top-color: var(--link-color-hover);
    }
  }

  img {
    border-radius: 50%;
    object-fit: cover;
    width: 40px;
    height: 40px;
  }
}

.message-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .name {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: var(--main-color);
    margin: 0;
  }
}

.message-content {
  padding-left: 16px;
  width: 100%;
}

.star-checkbox {
  input {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
  }

  label {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .dark & {
    color: var(--secondary-color);

    input:checked + label {
      color: var(--star);
    }
  }

  input:checked + label svg {
    fill: var(--star);
    transition: 0.2s;
  }
}

.message-line {
  font-size: 14px;
  line-height: 20px;
  margin: 8px 0;
  color: var(--secondary-color);
  opacity: 0.7;

  &.time {
    text-align: right;
    margin-bottom: 0;
  }
  ul {
    padding-left: 20px;
    border-left: 3px solid red;
  }
}

.project-boxes {
  margin: 0 -8px;
  overflow-y: auto;

  &.jsGridView {
    display: flex;
    flex-wrap: wrap;

    .project-box-wrapper {
      width: 33.3%;
    }
  }

  &.jsListView {
    .project-box {
      display: flex;
      border-radius: 10px;
      position: relative;
      > * {
        margin-right: 24px;
      }
    }

    .more-wrapper {
      position: absolute;
      right: 16px;
      top: 16px;
    }

    .project-box-content-header {
      order: 1;
      max-width: 120px;
    }

    .project-box-header {
      order: 2;
    }

    .project-box-footer {
      order: 3;
      padding-top: 0;
      flex-direction: column;
      justify-content: flex-start;
    }

    .project-box-footer:after {
      display: none;
    }

    .participants {
      margin-bottom: 8px;
    }

    .project-box-content-header p {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .project-box-header > span {
      position: absolute;
      bottom: 16px;
      left: 16px;
      font-size: 12px;
    }

    .box-progress-wrapper {
      order: 3;
      flex: 1;
    }
  }
}

.project-box {
  --main-color-card: #dbf6fd;
  border-radius: 30px;
  padding: 16px;
  background-color: var(--main-color-card);

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    color: var(--main-color);

    span {
      color: #4a4a4a;
      opacity: 0.7;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &-content-header {
    margin-bottom: 16px;

    p {
      margin: 0;
    }
  }

  &-wrapper {
    padding: 8px;
    transition: 0.2s;
  }
}

.project-btn-more {
  padding: 0;
  height: 14px;
  width: 24px;
  height: 24px;
  position: relative;
  background-color: transparent;
  border: none;
  flex-shrink: 0;

  /*&:after, &:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--main-color);
    opacity: .8;
    left: 50%;
    transform: translatex(-50%);
  }

  &:before { top: 0;}
  &:after { bottom: 0; }*/
}

.more-wrapper {
  position: relative;
}

.box-content-header {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  opacity: 0.7;
}

.box-content-subheader {
  font-size: 14px;
  line-height: 24px;
  opacity: 0.7;
  font-weight: 600;
}

.box-progress {
  display: block;
  height: 4px;
  border-radius: 6px;

  &-bar {
    width: 100%;
    height: 4px;
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;
    margin: 8px 0;
  }

  &-header {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin: 0;
  }

  &-wrapper {
  }

  &-percentage {
    text-align: right;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }
}

.project-box-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
    width: calc(100% + 32px);
    top: 0;
    left: -16px;
    height: 1px;
  }
}

.participants {
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;

    &:not(:first-child) {
      margin-left: -8px;
    }
  }
}

.add-participant {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.6);
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.days-left {
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  border-radius: 20px;
  flex-shrink: 0;
  padding: 6px 16px;
  font-weight: 700;
}

.mode-switch.active .moon {
  fill: var(--main-color);
}

.messages-btn {
  border-radius: 4px 0 0 4px;
  position: absolute;
  right: 0;
  top: 58px;
  background-color: var(--message-btn);
  border: none;
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: none;
}

@media screen and (max-width: 980px) {
  .project-boxes.jsGridView .project-box-wrapper {
    width: 50%;
  }

  .status-number,
  .status-type {
    font-size: 14px;
  }

  .status-type:after {
    width: 4px;
    height: 4px;
  }

  .item-status {
    margin-right: 0;
  }
}

@media screen and (max-width: 880px) {
  .messages-section {
    transform: translateX(100%);
    position: absolute;
    opacity: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    width: 100%;

    .messages-close {
      display: block;
    }
  }

  .messages-btn {
    display: flex;
  }
}

@media screen and (max-width: 720px) {
  .app-name,
  .profile-btn span {
    display: none;
  }

  .add-btn,
  .notification-btn,
  .mode-switch {
    width: 20px;
    height: 20px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .app-header-right button {
    margin-left: 4px;
  }
}

@media screen and (max-width: 520px) {
  .projects-section {
    overflow: auto;
  }
  .project-boxes {
    overflow-y: visible;
  }

  .app-sidebar,
  .app-icon {
    display: none;
  }

  .app-content {
    padding: 16px 12px 24px 12px;
  }

  .status-number,
  .status-type {
    font-size: 10px;
  }

  .view-btn {
    width: 24px;
    height: 24px;
  }

  .app-header {
    padding: 16px 10px;
  }

  .search-input {
    max-width: 120px;
  }

  .project-boxes.jsGridView .project-box-wrapper {
    width: 100%;
  }

  .projects-section {
    padding: 24px 16px 0 16px;
  }

  .profile-btn img {
    width: 24px;
    height: 24px;
  }

  .app-header {
    padding: 10px;
  }

  .projects-section-header p,
  .projects-section-header .time {
    font-size: 18px;
  }

  .status-type {
    padding-right: 4px;

    &:after {
      display: none;
    }
  }

  .search-input {
    font-size: 14px;
  }

  .messages-btn {
    top: 48px;
  }

  .box-content-header {
    font-size: 12px;
    line-height: 16px;
  }

  .box-content-subheader {
    font-size: 12px;
    line-height: 16px;
  }

  .project-boxes.jsListView .project-box-header > span {
    font-size: 10px;
  }

  .box-progress-header {
    font-size: 12px;
  }

  .box-progress-percentage {
    font-size: 10px;
  }

  .days-left {
    font-size: 8px;
    padding: 6px 6px;
    text-align: center;
  }

  .project-boxes.jsListView .project-box > * {
    margin-right: 10px;
  }

  .project-boxes.jsListView .more-wrapper {
    right: 2px;
    top: 10px;
  }
}
</style>
