<template>
  <div class="config-ops-container">
    <span hidden>{{ state.totalPrice }}</span>
    <div
      class="config-ops-container__item"
      v-for="config in state.configList"
      :key="config.name"
    >
      <h1 class="font-bold mb-2">{{ config.name }}</h1>
      <h3 class="font-thin text-xs">{{ config.des }}</h3>
      <div class="config-ops-container__item__options">
        <div
          class="config-ops-container__item__options__item"
          :class="{ 'is-choice': config.selectedOptionId === option.id }"
          v-for="option in config.options"
          :key="option.id"
          @click="onClickOption(config, option)"
        >
          <span>{{ option.text }}</span>
          <span class="price-display">
            <span v-if="option.priceDisplay > 0">+ </span>
            {{
              Number(option.priceDisplay).toLocaleString("it-IT", {
                style: "currency",
                currency: "VND",
              })
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ConfigCategory } from "@/components/cart/config-opions/config-category";
import { ConfigOption } from "@/components/cart/config-opions/config-option";
import { computed, reactive } from "vue";
const emit = defineEmits(["on-config-price-change"]);
const state = reactive({
  configList: [
    // new ConfigCategory(
    //   "Ống Pvc",
    //   "Ống Nhựa",
    //   [
    //     new ConfigOption(1, "Ống Sanking", 1200000),
    //     new ConfigOption(2, "Ống Bình Minh", 100000),
    //   ],
    //   1
    // ),
    // new ConfigCategory(
    //   "Kính",
    //   "Siêu trong",
    //   [
    //     new ConfigOption(1, "Siêu Trong", 1500000),
    //     new ConfigOption(2, "Thường", 1200000),
    //     new ConfigOption(3, "Siêu trong 3 mặt", 1400000),
    //   ],
    //   2
    // ),

    new ConfigCategory(
      "Tủ điện",
      "Tủ điện được thiết kế để chống nước",
      [new ConfigOption(1, "Có", 1000000), new ConfigOption(2, "Bỏ Qua", 0)],
      1
    ),
    new ConfigCategory(
      "Keo",
      "Keo dán kính",
      [new ConfigOption(1, "Keo đen", 0), new ConfigOption(2, "Keo trắng", 0)],
      2
    ),

    new ConfigCategory(
      "Layout",
      "Layout sẽ đi theo bộ",
      [new ConfigOption(1, "Có", 500000), new ConfigOption(2, "Bỏ Qua", 0)],
      1
    ),
    new ConfigCategory(
      "Tất lọc",
      "Tất lọc chính hãng Aqua Excel",
      [new ConfigOption(1, "Có", 200000), new ConfigOption(2, "Bỏ Qua", 0)],
      1
    ),
    new ConfigCategory(
      "Gỗ Nhựa Kệ",
      "Chất liệu gỗ nhựa để bọc kệ",
      [
        new ConfigOption(1, "Loại thường (dễ bám bẩn)", 0),
        new ConfigOption(2, "Bọc film (dễ lau chùi)", 800000),
      ],
      1
    ),
  ],
  totalPrice: computed(() => {
    let total = 0;
    state.configList.forEach((x) => {
      total = total + x.selectedOption.price;
    });
    emit("on-config-price-change", {
      total,
      configs: JSON.stringify(state.configList),
    });
    return total;
  }),
});
const onClickOption = (category, option) => {
  category.selectedOption = category.options.find((x) => x.id === option.id);

  category.selectedOptionId = category.selectedOption.id;
  category.reloadPriceDisplay();
  console.log("dkíai");
};
defineExpose({ emit, configList: state.configList });
</script>
<style scoped lang="scss">
.config-ops-container {
  &__item {
    border-bottom: 1px solid #d2d2d7;
    padding: 16px;
    margin: 5px;
    cursor: pointer;
    &__options {
      &__item {
        border: 1px solid #ededed;
        border-radius: 12px;
        padding: 16px;
        margin: 16px 0;
        display: flex;
        justify-content: space-between;
        &.is-choice {
          background: #4ecdc4;
          color: white;
          transition: 0.5s;
          .price-display {
            opacity: 0;
          }
        }
        &:hover {
          border: 1px solid #ededed;
        }
      }
    }
  }
}
</style>
