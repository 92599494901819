<template>
  <div class="detail-ctn" :key="product.id">
    <div>
      <section class="products" v-viewer="{ movable: false }">
        <div
          v-for="(url, index) in detail.images"
          v-bind:key="url"
          @click="showFullImage(url, index, $event)"
          :class="{
            'col-md-4 portfolio-item product-card': true,
            'flex-100-basic': isVideo(url),
          }"
        >
          <video
            v-if="isVideo(url)"
            :src="url + '#t=5'"
            autoplay
            :ref="'videoRef' + index"
            width="1000"
            height="500"
            controls
          >
            <source :src="url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <img v-else :src="url" alt="" data-enlargable width="100" />
        </div>
      </section>
    </div>
    <div class="info card">
      <div>{{ product.details.description }}</div>

      <div class="care" v-if="product.details.levelBars">
        <AILevelBar
          :description="product.details.levelBars[2].description"
          :start="product.details.levelBars[2].startText"
          :end="product.details.levelBars[2].endText"
          :level="product.details.levelBars[2].level"
        />
        <AILevelBar
          :description="product.details.levelBars[0].description"
          :start="product.details.levelBars[0].startText"
          :end="product.details.levelBars[0].endText"
          :level="product.details.levelBars[0].level"
        />
        <AILevelBar
          :description="product.details.levelBars[1].description"
          :start="product.details.levelBars[1].startText"
          :end="product.details.levelBars[1].endText"
          :level="product.details.levelBars[1].level"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { directive as viewer } from "v-viewer";
import { computed } from "vue";
import AILevelBar from "@/components/AILevelBar";
const props = defineProps({
  product: Object,
});
const vViewer = viewer({
  debug: true,
});
const detail = computed(() => {
  return {
    images: [props.product.details.urlVideo, props.product.details.urlImage],
  };
});
const showFullImage = (src, index, $event) => {
  if (isVideo(src)) {
    console.log(index);
    $event.target.src = src; // Remove the time, which is set in the URL
    $event.target.play(); // Play the video
    $event.target.controls = true; // Enable controls
    toggleFullscreen($event);
  } else {
    const viewer = document.querySelector(".images").$viewer;
    viewer.show();
  }
};
const isVideo = (src) => {
  if (!src) {
    return false;
  }
  const videos = ["mp4", "3gp", "ogg"];
  const url = new URL(src);

  const extension = url.pathname.split(".")[1];
  return videos.includes(extension);
};
const toggleFullscreen = ($event) => {
  const video = $event.target;
  if (video.requestFullscreen) {
    video.requestFullscreen();
  } else if (video.mozRequestFullScreen) {
    /* Firefox */
    video.mozRequestFullScreen();
  } else if (video.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    video.webkitRequestFullscreen();
  } else if (video.msRequestFullscreen) {
    /* IE/Edge */
    video.msRequestFullscreen();
  }
};
defineExpose({
  detail,
  showFullImage,
  isVideo,
  toggleFullscreen,
});
</script>

<style lang="scss" scoped>
.products {
  display: flex;
  flex-wrap: wrap;
}

.product-card {
  cursor: zoom-in;
  display: flex;
  flex-direction: column;

  padding: 2%;
  flex-basis: 25%;
  img {
    border-radius: 10%;
  }
  &.flex-100-basic {
    flex-basis: 100% !important;
  }
}

.product-info {
  margin-top: auto;
  padding-top: 20px;
  text-align: center;
}

@media (max-width: 920px) {
  .product-card {
    flex: 1 21%;
  }

  .products .product-card:first-child,
  .products .product-card:nth-child(2) {
    flex: 2 46%;
  }
}

@media (max-width: 600px) {
  .product-card {
    flex: 1 46%;
  }
}

@media (max-width: 480px) {
  h1 {
    margin-bottom: 20px;
  }

  .product-filter {
    flex-direction: column;
  }

  .sort {
    align-self: flex-start;
  }
}
.detail-ctn {
  padding: 20px;
  .info {
    padding: 10px 20px;
    display: flex;
    div:first-child {
      flex-basis: 50%;
    }
  }
}
</style>
