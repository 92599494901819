<template>
  <div
    class="side-right sidenav"
    :class="{ 'is-show': state.isToggle }"
    id="ai-side-right"
    ref="root"
  >
    <div class="side-right__header">
      <slot name="header"></slot>
    </div>
    <div class="side-right__content">
      <!-- <router-link to="/CartShopVue">Home</router-link> |
      <router-link to="/CartInfoVue">About</router-link> -->
      <slot name="content"></slot>
    </div>
    <div class="side-right__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";

let state = reactive({
  isToggle: false,
});
const root = ref(null);
const toggle = () => {
  state.isToggle = !state.isToggle;
  window.$bus.emit("on-show-overlay", state.isToggle);
  if (state.isToggle) {
    openNav();
  } else {
    closeNav();
  }
};
window.$bus.on("on-click-outside", () => {
  const isShowing = root.value.style.width === "40%";
  if (isShowing) {
    toggle();
  }
});
const openNav = () => {
  root.value.style.width = "40%";
  window.$("body").css("overflow", "hidden");
  window.$("body").toggleClass("ai-side-right-show", true);
};

const closeNav = () => {
  root.value.style.width = "0";
  window.$("body").css("overflow", "unset");
  window.$("body").toggleClass("ai-side-right-show", false);
};
defineExpose({
  toggle,
});
</script>

<style lang="scss" scoped>
.side-right {
  height: 100vh;
  display: flex;
  flex: 10;
  flex-direction: column;
  &__header {
    flex: 1;
    padding: 32px;
    background: #fff;
    border-bottom: 1px solid #ededed;
    -webkit-box-shadow: 0 0.5px 0 0 #ededed;
    box-shadow: 0 0.5px 0 0 #ededed;
    position: sticky;
    top: 0;
    z-index: 9999;
  }

  &__content {
    margin: 32px;
    flex: 8;
  }

  &__footer {
    border-top: 1px solid #dadce0;
    flex: 1;
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 9999;
  }
}

/* The side navigation menu */
.sidenav {
  height: 100%;
  /* 100% Full-height */
  width: 0;
  /* 0 width - change this with JavaScript */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  right: 0;
  background-color: #fff;
  /* Black*/
  overflow-x: hidden;
  /* Place content 60px from the top */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */
  box-shadow: 0 5px 15px 3px rgb(0 0 0 / 20%);
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.wrapper {
  width: 100%;
  min-height: 100vh;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}

.slide-enter {
  transform: translate(100%, 0);
}

.slide-leave-to {
  transform: translate(-100%, 0);
}

.slideback-leave-active,
.slideback-enter-active {
  transition: 0.5s;
}

.slideback-enter {
  transform: translate(-100%, 0);
}

.slideback-leave-to {
  transform: translate(100%, 0);
}
</style>
<style lang="scss">
.is-mobile {
  &.ai-side-right-show {
    .mobile-nav-toggle {
      display: none !important;
    }
  }
  .is-show {
    width: 80% !important;
  }
}
</style>
