// import { getCurrentInstance } from "vue";

import { filter, some } from "lodash";
import { URLS } from "@/const";
import axios from "axios";
export function useShop() {
  const mappingProducts = () => {
    const products = JSON.parse(localStorage.cartProducts || "[]");
    const newProducts = [];
    products.forEach((element) => {
      element.count = filter(products, (x) => x.id === element.id)?.length || 1;
      const isExist = some(newProducts, (p) => p.id === element.id);
      if (!isExist) {
        newProducts.push(element);
      }
    });
    return newProducts;
  };
  const clearCart = () => {
    localStorage.cartProducts = "";
  };
  const hasProductOnCart = () => {
    return !!localStorage.cartProducts;
  };
  const getProducts = async (callback) => {
    // const categories = Object.values(SHOP_ITEM_TYPE[props.category]);

    let items = [];
    axios
      .get(process.env.VUE_APP_API_BASE + URLS.GET_PRODUCTS, {
        headers: {
          "auth-token": `${localStorage.aiReeferToken}`,
        },
      })
      .then((response) => {
        response.data.products.forEach((product) => {
          // console.log(`${doc.id} => ${doc.data()}`);
          items.push({
            id: product.id,
            url: product.url,
            name: product.name,
            type: product.type,
            ctg: product.ctg,
            quantity: product.quantity,
            price: product.price,
            priceText: product.priceText,
            details: JSON.parse(
              product.details ||
                JSON.stringify({
                  urlVideo: "",
                  description: "",
                  urlImage: "",
                })
            ),
            isShow: true,
          });
        });
        callback(items);
      });
  };
  const getOrders = async (callback, failedCallBack = undefined) => {
    // const categories = Object.values(SHOP_ITEM_TYPE[props.category]);
    let items = [];
    axios
      .get(process.env.VUE_APP_API_BASE + URLS.GET_ORDERS, {
        headers: {
          "auth-token": `${localStorage.aiReeferToken}`,
        },
      })
      .then((response) => {
        response.data.orders.forEach((order) => {
          // console.log(`${doc.id} => ${doc.data()}`);
          items.push({
            id: order.id,
            products: order.products,
            buyerInfo: order.buyerInfo,
          });
        });
        callback(items);
      })
      .catch((error) => {
        failedCallBack && failedCallBack(error);
      });
  };
  return {
    mappingProducts,
    clearCart,
    hasProductOnCart,
    getProducts,
    getOrders,
  };
}
