<template>
  <div class="page-wrap">
    <div>{{ description }}</div>
    <div class="skill-bar-container">
      <span class="skill-name">{{ start }}</span>
      <span class="skill-percent">{{ end }}</span>
      <span class="skill-bar" :class="[levelClass]"></span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  id: String,
  level: Number,
  start: String,
  end: String,
  description: String,
});
const levelClass = "level" + props.level;
defineExpose({
  levelClass,
});
</script>

<style lang="scss" scoped>
.page-wrap {
  background-color: #fff;
  margin: 0 auto;
  padding-top: 3em;
  font-size: 13px;
}

//The skill bars

$skill1: rgba(78, 205, 196, 0.2);
$skill2: rgba(78, 205, 196, 0.4);
$skill3: rgba(78, 205, 196, 0.6);
$skill4: rgba(78, 205, 196, 0.8);
$skill5: #4ecdc4;

.skill-bar-container {
  position: relative;
  width: 100%;
  border-radius: 4px;
  background-color: whitesmoke;
  margin-bottom: 0.5em;
  .skill-bar {
    display: block;
    border-radius: 4px;
    height: 25px;
    //background-color: $skillColor; //#d7edfa
    &.level1 {
      width: 20%;
      background-color: $skill1;
    }
    &.level2 {
      width: 40%;
      background-color: $skill2;
    }
    &.level3 {
      width: 60%;
      background-color: $skill3;
    }
    &.level4 {
      width: 80%;
      background-color: $skill4;
    }
    &.level5 {
      width: 100%;
      background-color: $skill5;
    }
  }
  .skill-name {
    position: absolute;
    top: 5px;
    left: 10px;

    color: #999; //#333
    //font-weight: 600;
  }
  .skill-percent {
    color: #333; //#333
    position: absolute;
    top: 5px;
    right: 10px;
  }
}
</style>
