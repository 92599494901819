<template>
  <div class="container">
    <div class="container-form">
      <form action="/action_page.php">
        <div class="row">
          <div class="col-100">
            <label for="fname"
              ><i class="fa fa-user"></i> Tên người nhận:</label
            >
            <input
              v-model="info.name"
              type="text"
              id="fname"
              name="firstname"
              placeholder="John M. Doe"
            />
            <label for="email"><i class="fa fa-envelope"></i> Email</label>
            <input
              v-model="info.mail"
              type="text"
              id="email"
              name="email"
              placeholder="john@example.com"
            />
            <label for="adr"
              ><i class="fa fa-address-card-o"></i> Địa chỉ nhận hàng:</label
            >
            <input
              v-model="info.andress"
              type="text"
              id="adr"
              name="address"
              placeholder="542 W. 15th Street"
            />
            <label for="city"
              ><i class="fa fa-institution"></i> Thành phố:</label
            >
            <input
              v-model="info.city"
              type="text"
              id="city"
              name="city"
              placeholder="New York"
            />
            <label for="tel"
              ><i class="fa fa-institution"></i> Số điện thoại</label
            >
            <input
              v-model="info.phone"
              type="text"
              id="tel"
              name="tel"
              placeholder="039...."
            />
          </div>
        </div>
        <!--        <label>-->
        <!--          <input type="checkbox" checked="checked" name="sameadr" />-->
        <!--          Shipping address same as billing-->
        <!--        </label>-->
        <!--        <input type="submit" value="Continue to checkout" class="btn" />-->
      </form>
    </div>
  </div>
</template>
<script setup>
import { reactive } from "vue";

const info = reactive({
  name: "",
  mail: "",
  phone: "",
  city: "",
  andress: "",
});
const isCorrectInfo = () => {
  return Object.values(info).every((x) => x);
};
defineExpose({
  info,
  isCorrectInfo,
});
</script>
<style scoped lang="scss">
.container {
}
.btn {
  background-color: #04aa6d;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: none;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
}

.btn:hover {
  background-color: #45a049;
}

span.price {
  float: right;
  color: grey;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (and change the direction - make the "cart" column go on top) */
@media (max-width: 800px) {
  .row {
    flex-direction: column-reverse;
  }
  .col-25 {
    margin-bottom: 20px;
  }
}
</style>
