<template>
  <!-- Main -->
  <main role="main" class="example">
    <!-- Image -->
    <section class="image" v-for="content in listContent" :key="content">
      <div
        class="image__bg"
        :style="{
          'background-image': 'url(' + content.imageSrc + ')',
        }"
      >
        <span class="description-product" v-html="content.content"></span>
      </div>
    </section>
  </main>
</template>

<script setup>
import { onMounted } from "vue";
const listContent = [
  {
    imageSrc:
      "https://lh3.googleusercontent.com/pw/AMWts8CzoAf9Be4Ciy4NCT4spFmSSTme9LOcaKyJv4EUVPBl_oyv_H63UqjTJ5r7fT4SL6W6uQbpR-GBTt3iQTK372tKErUfpupl61F20muqaeEQl2mYGk48uC3187m1qpN_JXY59EHdWPyAOOc0EqPGwJp9=w1266-h944-s-no?authuser=0",
    content: "",
  },
  {
    imageSrc:
      "https://lh3.googleusercontent.com/pw/AMWts8B7B6BckcC9h11spjmD3S0kRWg5bR9nCWz5fhdcMsJbpMa9RiRQuV45wl_c7BIIV9k95Wf4D5LAruL2uh22YZRfjVjGloiZluwthRZRlGkTndHLfmk66hWiyu-oNqFFctqi5OI68QqlMK2bJi1lUuqC=w1416-h944-s-no?authuser=0",
    content:
      '<div class="description-product"><span class="info-title">Đầu out</span><br><span\n' +
      '                    class="info-content"> được thiết kế dạng lốc xoáy có thể cải thiện dòng chảy\n' +
      "                rộng hơn</span></div>\n",
  },
  {
    imageSrc:
      "https://lh3.googleusercontent.com/pw/AMWts8BUv3B51bpUcsfmrXt5x7hQYevrG6CXK3oQ1efHqI6PLR9cRrNZhPwXjMTvXf59o5N1f4AvH9gZV7b6-Uat48vw-KWYocQ6-wqxHVgGPwhDh4lbF5OSNq0hascbHICrgDykY9iSnyp5T83Af8ajBC_O=w1416-h944-s-no?authuser=0",
    content:
      '<div class="description-product left-side"><span class="info-title">\n' +
      "                Kính\n" +
      '            </span><br><span class="info-content">Kính nhật cường 10 li, được mài vi tính và dán dấu keo, không bóng khí li ti. Được liên kết bởi lớp Silicon dày 1 li bảo hành 2 năm</span>\n' +
      "            </div>",
  },
  {
    imageSrc:
      "https://lh3.googleusercontent.com/pw/AMWts8D2sjtMxQ382wxa2Ncsw9bo1ArXLQQcHNkcfQ6r12j3s_BXW6jRvZ8WJd8bm4aHSZGLc6rBCC1rHgZNcguFTQhln4yUVULik1yHgQAxDEwXXUh0s3G3pca4Jsxj5hRGdtLS6KQL-2x5In7mWM7d0sFu=w1416-h944-s-no?authuser=0",
    content:
      ' <div class="description-product right-bottom-side"><span class="info-title"> Hộp nước</span><span class="info-content"> thiết kế 2 lớp\n' +
      "                <ul>\n" +
      "                    <li> Kính: tạo sự chắc chắn của hộp nước, bảo đảm nước không bị leak từ đáy</li>\n" +
      "                    <li> Acrylic: tạo sự tinh tế và thẩm mĩ cho hộp nước. Mạch lưới 3li dày 5 phân chắc chắn</li>\n" +
      "                </ul>Chống ồn hoàn toàn</span>\n" +
      "            </div>",
  },
  {
    imageSrc:
      "https://lh3.googleusercontent.com/pw/AMWts8BECHThrjV9cAYcheM8sIjTlJiBeV8izVapL1OQ51MC83y2BrZIJK_MZ96YHwpQC-YETKvVnLHZE4wPZy0lrOAToQuOp8yt8ZJfhFXTjrzy0MxI0wjbeVI2FCJhD-GDgBbrT3J7nMoBYBB_AKABFXcp=w1415-h943-s-no?authuser=0",
    content:
      ' <div class="description-product left-side">\n' +
      '                <span class="info-title">Chân Kệ</span>\n' +
      '                <br><span class="info-content"> làm bằng chất liệu Nhôm, chắc chắn, chống nước và nước\n' +
      "                mặn tuyệt đối. Dễ dàng lắp đặt, dễ dàng tái sử dụng khi muốn lên hồ mới</span>\n" +
      "            </div>",
  },
  {
    imageSrc:
      "https://lh3.googleusercontent.com/pw/AMWts8A6ok2KxulxSy14XvSA6x-lpkpViXJgjRYSzQpgPJ8wiciGPriEb8xN2iZvkQisgtTsn_RnUS-fdtGn1Sk4Ix_8dL95jkZ5jmYB4JiwS3NUzt30dQo6XS7HKmqO6m9xnX3ITA8icn5_qBiajBM4ZsXP=w1416-h944-s-no?authuser=0",
    content: "",
  },
  {
    imageSrc:
      "https://lh3.googleusercontent.com/pw/AMWts8AX9E1HLWQijU0YKqwjch3bI_cw09pZhKAvLUJ9yc6EK0j2xhPPk7jjrr3xddjnfBXU1bJwO6O8NfI41W8spfiFosKdgG65eT5_DDt8nEa5sa_HVxsgt9sFvH-HUlECjiXLFyUVDvQdYE2WKgqYEfLE=w1416-h944-s-no?authuser=0",
    content: "",
  },
  {
    imageSrc:
      "https://lh3.googleusercontent.com/pw/AMWts8CdanYTbESBN4KGx82GtKvliPecgwoJD75W3ZETAnxkmYojOuFonh8PdRl7sID7d9BmAoeyosS0f2sYBXv8u0zuG6tLM2CLgSZll8viw80hGTlRc156JKpeZt4GxWCp0bT1yfM1AhvzHkvhJT1pHyV-=w629-h944-s-no?authuser=0",
    content: "",
  },
];
onMounted(() => {
  const OpacityScroll = (() => {
    let s;

    return {
      settings() {
        return {
          windowHeight: window.innerHeight,
          halfWindowHeight: window.innerHeight / 2,
          documentHeight: document.body.scrollHeight,
        };
      },

      init(page) {
        s = this.settings();
        this.bindEvents(page);
      },

      bindEvents(page) {
        this.opacityScroll(page);
      },

      opacityScroll() {
        const children = document.querySelectorAll(`.image`);

        [].forEach.call(children, (child, idx) => {
          child.style.left = 0;

          if (idx === 0) {
            child.style.position = "fixed";
            child.style.top = 0;
            child.style.zIndex = 0;
          } else {
            child.style.position = "absolute";
            child.style.top = `${idx}00vh`;
            child.style.zIndex = idx;

            let scrollOffset =
              child.getBoundingClientRect().top + document.body.scrollTop;
            let opacityTrigger = s.windowHeight * idx - s.halfWindowHeight;

            window.addEventListener("resize", () => {
              scrollOffset =
                child.getBoundingClientRect().top + document.body.scrollTop;
              s.windowHeight = window.innerHeight;
              s.halfWindowHeight = s.windowHeight / 2;
              s.documentHeight = document.body.scrollHeight;
              opacityTrigger = s.windowHeight * idx - s.halfWindowHeight;
            });

            const opacityChange = () => {
              const scrollTop = document.documentElement.scrollTop;
              const prev = child.previousElementSibling;
              const opacity =
                1 - ((scrollTop - opacityTrigger) / scrollTop) * idx * 2;

              if (scrollTop >= opacityTrigger) {
                prev.style.opacity = opacity > 0 ? opacity : 0;
              } else {
                prev.style.opacity = 1;
              }

              if (
                scrollTop >= scrollOffset &&
                scrollTop + s.windowHeight !== s.documentHeight
              ) {
                child.style.position = "fixed";
                child.style.top = 0;
              } else {
                child.style.position = "absolute";
                child.style.top = `${idx}00vh`;
                child.style.left = 0;
                child.style.zIndex = idx;
              }
            };

            window.addEventListener("scroll", opacityChange);
            window.addEventListener("resize", opacityChange);
          }
        });
      },
    };
  })();

  OpacityScroll.init("example");
});
</script>

<style scoped lang="scss">
body {
  background-color: #000;
}

.image {
  position: relative;

  height: 100vh;
  width: 100%;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    height: 100%;
    width: 100%;

    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.description-product {
  width: 60%;
  height: fit-content;
  position: absolute;
  left: 15%;
  top: 30%;
  & ul,
  li {
    list-style: "#";
    padding-left: 5px;
  }
  & ul li::marker {
    padding-left: 5px;
    color: #4ecdc4;
  }
  &.right-bottom-side {
    left: 60%;
    top: 54%;
  }
  .info-title {
    font-weight: 600;
    color: #4ecdc4;
    font-size: 64px;
  }
  .info-content {
    color: #ededed;
    font-size: 24px;
  }
}
</style>
