<template>
  <div class="project-box" :style="randomColorCardProduct()">
    <div class="project-box-header">
      <span> {{ product.id }}</span>
      <div class="more-wrapper">
        <button @click="isOpen = !isOpen" class="project-btn-more">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-more-vertical"
          >
            <circle cx="12" cy="12" r="1" />
            <circle cx="12" cy="5" r="1" />
            <circle cx="12" cy="19" r="1" />
          </svg>
        </button>

        <div class="navbar-right relative">
          <button
            v-if="isOpen"
            @click="isOpen = false"
            tabindex="-1"
            class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default"
          ></button>
          <div
            v-if="isOpen"
            class="absolute top-auto left-0 w-48 py-2 mt-2 rounded-lg border-gray-900 bg-white shadow-xl"
          >
            <div
              @click="removeProduct(product.id)"
              href=""
              class="text-sm text-gray-900 hover:bg-blue-600 hover:text-white block px-4 py-2 cursor-pointer"
            >
              Xoá Sản Phẩm
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="project-box-content-header">
      <p class="box-content-header">{{ product.name }}</p>
      <p class="box-content-subheader">
        {{
          Number(product.price).toLocaleString("it-IT", {
            style: "currency",
            currency: "VND",
          })
        }}
      </p>
      <img
        v-lazy="product.url"
        style="height: 100px; width: 100px; text-align: center"
        class="img-fluid"
      />
    </div>
    <div class="project-box-footer">
      <div class="participants">
        <span class="font-size-bold"> {{ product.quantity }} cái</span>
      </div>
      <div
        class="days-left"
        @click="$emit('on-click-update', product)"
        style="color: #ff942e"
      >
        Cập Nhật
      </div>
    </div>
  </div>
</template>

<script setup>
import { sample } from "lodash/collection";
import { ref } from "vue";
const props = defineProps(["product"]);
const emit = defineEmits(["on-removed-product"]);
let isOpen = ref(false);
const randomColorCardProduct = () => {
  const colors = ["#fee4cb", "#e9e7fd", "#ffd3e2", "#c8f7dc", "#d5deff"];
  return {
    "background-color": sample(colors),
  };
};
const removeProduct = async (id) => {
  emit("on-removed-product", props.product);
  console.log(id);
  // await deleteDoc(doc(db, "ai-reefer-shop", id));
  isOpen.value = false;
};
</script>

<style scoped></style>
