// import { getCurrentInstance } from "vue";

import { toArray, forEach } from "lodash";
import { URLS } from "@/const";
import axios from "axios";
export function useOrder() {
  const reformatConfigList = (configs) => {
    const result = [];
    forEach(configs, (config) => {
      result.push({
        name: config.name,
        choice: config.options.find((opt) => opt.id === config.selectedOptionId)
          .text,
      });
    });
    return JSON.stringify(result);
  };
  const createOrder = async (products, infoBuyer, callback) => {
    const payload = {
      products,
      infoBuyer,
    };
    axios
      .post(process.env.VUE_APP_API_BASE + URLS.GET_ORDERS, payload)
      .then((response) => {
        callback && callback(response);
      });
  };
  const createProduct = async (newProducts, callback) => {
    const newCloneProducts = JSON.parse(JSON.stringify(newProducts));
    newCloneProducts.details = JSON.stringify(newCloneProducts.details);

    const config = {
      headers: {
        "auth-token": `${localStorage.aiReeferToken}`,
      },
    };
    axios
      .post(
        process.env.VUE_APP_API_BASE + URLS.POST_PRODUCT,
        newCloneProducts,
        config
      )
      .then((response) => {
        callback && callback(response);
      });
  };

  const login = (password, callback = undefined, errorCallBack = undefined) => {
    axios
      .get(process.env.VUE_APP_API_BASE + URLS.LOGIN + "/" + password)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        errorCallBack && errorCallBack(error);
      });
  };
  return {
    reformatConfigList,
    createOrder,
    createProduct,
    login,
  };
}
