<template>
  <div>
    <router-view />
  </div>
</template>
<script setup></script>
<style scoped lang="scss">
html,
body {
  width: 100%;
  height: 100vh;
  margin: 0;
}

body {
  font-family: "DM Sans", sans-serif;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: var(--app-container);
}
</style>
