<template>
  <div class="form-popup" id="myForm">
    <div class="form-container">
      <label for="psw"><b>Password</b></label>
      <input
        v-model="password"
        type="password"
        placeholder="Enter Password"
        name="psw"
        required
      />

      <button type="submit" @click="onLogin" class="btn">Login</button>
      <button type="button" class="btn cancel" @click="closeForm">Close</button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useOrder } from "@/composables/useOrder";

const order = useOrder();
const password = ref("");
const closeForm = () => {
  document.getElementById("myForm").style.display = "none";
};
const onLogin = () => {
  order.login(
    password.value,
    (res) => {
      localStorage.aiReeferToken = res.data;
      location.reload();
    },
    (error) => {
      alert(JSON.stringify(error));
    }
  );
};
defineExpose({
  closeForm,
  password,
  onLogin,
});
</script>

<style scoped>
.form-popup {
}

/* The popup form - hidden by default */
.form-popup {
  display: block;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width input fields */
.form-container input[type="text"],
.form-container input[type="password"] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
}

/* When the inputs get focus, do something */
.form-container input[type="text"]:focus,
.form-container input[type="password"]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #04aa6d;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}
</style>
