import { createApp } from "vue";
import mitt from "mitt";
import App from "./App.vue";

import router from "./router";
import VueLazyload from "vue-lazyload";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Directives from "./directives";
import "./App.scss";
const emitter = mitt();
const app = createApp(App);
app.use(router);
app.use(VueLazyload, {
  preLoad: 1.3,
  error: "assets/img/error.png",
  // loading: "assets/img/loading-gif.gif",
  attempt: 1,
  listenEvents: ["scroll"],
});
app.use(VueViewer);

Object.keys(Directives).forEach((name) =>
  app.directive(name, Directives[name])
);
window.$bus = emitter;
app.mount("#app");
app.config.globalProperties.$bus = emitter;
