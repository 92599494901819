export const PRODUCT_TYPE = {
  GEAR: 2,
  CORAL: 1,
};
export const CORAL_CATEGORIES = [
  {
    id: 1,
    name: "Zoa",
  },
  {
    id: 2,
    name: "SPS",
  },
  {
    id: 3,
    name: "LPS",
  },
];
export const SHOPPING_CATEGORIES = [
  {
    id: PRODUCT_TYPE.CORAL,
    name: "San Hô",
    isActive: true,
    products: [],
    description:
      "Chúng tôi sẽ bảo hành san hô tận bể của bạn và sau 1 tuần nếu chết hoặc mất màu",
  },
  {
    id: PRODUCT_TYPE.GEAR,
    name: "Thiết bị",
    isActive: false,
    products: [],
    description:
      "Hãy đầu tư phần cứng mạnh mẽ ngay từ đầu để có kết quả và trải nghiệm tuyệt vời nhất",
  },
];
export const SHOP_ITEM_TYPE = {
  // PRODUCT_TYPE.CORAL
  1: CORAL_CATEGORIES,
  // PRODUCT_TYPE.GEAR
  2: {
    LIGHT: {
      id: 1,
      name: "Light",
    },
    SALT: {
      id: 2,
      name: "Salt",
    },
    WAVE_MAKER: {
      id: 3,
      name: "Wave maker",
    },
    SKIMMER: {
      id: 4,
      name: "Skimmer",
    },
  },
};
export const CART_STEPS = {
  ITEMS: 0,
  CHECKOUT_INFO: 1,
  END: 2,
};
export const URLS = {
  GET_PRODUCTS: "api/v1/products",
  GET_ORDERS: "api/v1/orders",
  POST_PRODUCT: "api/v1/products",
  LOGIN: "get-token",
};
