<template>
  <section :id="id" :class="id" class="shop-container portfolio section-bg">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2></h2>
        <p v-html="description"></p>
      </div>

      <div class="row">
        <div
          class="col-lg-12 d-flex justify-content-center"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <ul id="portfolio-flters">
            <li
              data-filter="*"
              @click="onClickFilterProducts({ id: -1 }, $event)"
              class="filter-active"
            >
              All
            </li>
            <li
              v-for="ctg in categories"
              v-bind:key="ctg"
              @click="onClickFilterProducts(ctg, $event)"
              :data-filter="'.' + ctg.name"
            >
              {{ ctg.name }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row" data-aos="fade-up" data-aos-delay="200">
        <div class="row products-container">
          <section class="products">
            <div
              v-for="item in products"
              v-bind:key="item.url"
              v-show="item.isShow"
              :class="{
                'col-md-4 portfolio-item product-card': true,
                [toCtgName(item.ctg)]: true,
              }"
            >
              <div class="card">
                <div class="image-container">
                  <div class="first">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span class="discount">Còn Hàng</span>
                      <!--                    <span class="wishlist"><i class="fa fa-heart-o"></i></span>-->
                    </div>
                  </div>

                  <img
                    v-lazy="item.url"
                    class="img-fluid rounded thumbnail-image"
                  />
                </div>

                <div class="product-detail-container p-2">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h5 class="dress-name">{{ item.name }}</h5>

                    <div class="d-flex flex-column mb-2">
                      <span class="new-price">{{ displayPrice(item) }}</span>
                      <small class="old-price text-right">
                        <del>{{ displayPrice(item) }}</del></small
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-3">
                <div class="card voutchers">
                  <div class="voutcher-divider">
                    <div
                      class="voutcher-left text-center"
                      @click="onClickInfoProduct(item)"
                    >
                      <span class="voutcher-name">Thông tin</span>
                      <h5 class="voutcher-code">Chi Tiết</h5>
                    </div>
                    <div class="voutcher-right text-center border-left">
                      <button
                        @click="onProductClick(item, $event)"
                        class="ai-button"
                      >
                        <span>Mua</span>
                        <div class="cart">
                          <svg viewBox="0 0 36 26">
                            <polyline
                              points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"
                            ></polyline>
                            <polyline
                              points="15 13.5 17 15.5 22 10.5"
                            ></polyline>
                          </svg>
                        </div>
                      </button>
                      <!--                    <h5 class="discount-percent">20%</h5>-->
                      <!--                    <span class="off">Off</span>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <SideRightBar
      ref="productDetailSideBarRef"
      class="slide-bar-product-details"
    >
      <template v-slot:header>
        <div class="slide-bar-cart__header">
          <h1>{{ selectedProduct && selectedProduct.name }}</h1>
        </div>
      </template>
      <template v-slot:content>
        <AIProductDetails
          v-if="selectedProduct"
          :product="selectedProduct"
        ></AIProductDetails>
      </template>
    </SideRightBar>
  </section>
  <!-- End Portfolio Section -->
</template>
<script>
import { toRefs, reactive, onMounted, nextTick, computed, ref } from "vue";
import { buildFilter } from "../../templatejs.js";

import AIProductDetails from "@/components/AIProductDetails";
import SideRightBar from "@/components/SideRightBar";
import { CORAL_CATEGORIES, SHOP_ITEM_TYPE } from "@/const";
import { find } from "lodash";

export default {
  components: { SideRightBar, AIProductDetails },
  props: {
    id: String,
    name: String,
    description: String,
    products: Array,
    category: Number,
  },
  setup(props) {
    const productDetailSideBarRef = ref(null);
    const categories = computed(() => {
      return SHOP_ITEM_TYPE[Number(props.category)];
    });
    const state = reactive({
      items: {},
      isLoading: true,
      selectedProduct: undefined,
    });
    const toCtgName = (id) => {
      return find(CORAL_CATEGORIES, (item) => item.id === id).name;
    };
    const onProductClick = (product, $event) => {
      if (localStorage.cartProducts) {
        let cartProducts = JSON.parse(localStorage.cartProducts);
        cartProducts.push(product);
        localStorage.cartProducts = JSON.stringify(cartProducts);
      } else {
        let cartProducts = [];
        cartProducts.push(product);
        localStorage.cartProducts = JSON.stringify(cartProducts);
      }

      window.$bus.emit("on-localstorage-products-change");
      addingToCartAnimation($event);
    };
    const addingToCartAnimation = ($event) => {
      const button = window.$($event.currentTarget);
      if (!button.hasClass(".loading")) {
        button.addClass("loading");

        setTimeout(() => button.removeClass("loading"), 3700);
      }
      const cart = window.$(".cart-btn");
      button.addClass("sendtocart");
      setTimeout(function () {
        button.removeClass("sendtocart");
        cart.addClass("shake").attr("data-totalitems", 1);
        setTimeout(function () {
          cart.removeClass("shake");
        }, 1000);
      }, 1000);
    };
    onMounted(() => {
      nextTick(() => {
        setTimeout(() => {
          buildFilter();
        }, 3000);
      });
    });
    // expose to template and other options API hooks
    const onClickFilterProducts = (category, $event) => {
      activeCtgCss($event);
      const isSelectedAll = Boolean(category.id === -1);
      props.products.forEach((product) => {
        if (isSelectedAll) {
          product.isShow = true;
        } else {
          product.isShow = product.ctg === category.id;
        }
      });
    };
    const activeCtgCss = ($event) => {
      const allCtgs = window.$("#portfolio-flters li");
      allCtgs.each((index, child) => {
        child.classList = [];
      });
      window.$($event.target).addClass("filter-active");
    };
    const onClickInfoProduct = (product) => {
      console.log(product);
      state.selectedProduct = product;
      productDetailSideBarRef.value.toggle();
    };
    const displayPrice = (item) => {
      return (
        item.priceText ||
        Number(item.price).toLocaleString("it-IT", {
          style: "currency",
          currency: "VND",
        })
      );
    };
    return {
      ...toRefs(state),
      categories,
      onProductClick,
      onClickInfoProduct,
      toCtgName,
      onClickFilterProducts,
      productDetailSideBarRef,
      displayPrice,
    };
  },
};
</script>
<style lang="scss">
.slide-bar-product-details {
  .side-right {
    &__header {
      font-weight: bold;
      font-size: large;
    }
    &__footer {
      border-top: 0 !important;
    }
  }
}
.shop-container {
  .item-info {
    padding: 0 50px;
  }
  .portfolio-wrap {
    // background-color: #4ecdc42b;
    background-color: #fff;
    &::before {
      height: 300px;
    }

    img {
      padding: 50px;
    }

    .item-info {
      text-align: center;
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      .item-name {
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .item-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        row-gap: 10px;

        span {
          font-weight: bolder;
          // color: #4ecdc4;
        }

        .ai-button {
          position: relative;

          .cart-item {
            position: absolute;
            height: 24px;
            width: 24px;
            top: -10px;
            right: -10px;
          }
        }
      }
    }

    .portfolio-info {
      background: #fff;
      bottom: 150px;

      h4 {
        color: #555;
      }

      a {
        color: #4ecdc4;
      }
    }
  }
}

@keyframes xAxis {
  100% {
    transform: translateX(calc(50vw + 50px));
  }
}

@keyframes yAxis {
  100% {
    transform: translateY(calc(50vh - 400px));
  }
}

.ai-button {
  --background: #4ecdc4;
  --text: #fff;
  --cart: #fff;
  --tick: var(--background);
  position: relative;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  transform: scale(var(--scale, 1));
  transition: transform 0.4s cubic-bezier(0.36, 1.01, 0.32, 1.27);
  background: none;
  color: var(--text);

  &:active {
    --scale: 0.95;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    display: block;
    position: relative;
    padding-left: 24px;
    margin-left: -8px;
    line-height: 26px;
    transform: translateY(var(--span-y, 0));
    transition: transform 0.7s ease;

    &:before,
    &:after {
      content: "";
      width: var(--w, 2px);
      height: var(--h, 14px);
      border-radius: 1px;
      position: absolute;
      left: var(--l, 8px);
      top: var(--t, 6px);
      background: currentColor;
      transform: scale(0.75) rotate(var(--icon-r, 0deg))
        translateY(var(--icon-y, 0));
      transition: transform 0.65s ease 0.05s;
    }

    &:after {
      --w: 14px;
      --h: 2px;
      --l: 2px;
      --t: 12px;
    }
  }

  .cart {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -13px 0 0 -18px;
    transform-origin: 12px 23px;
    transform: translateX(-120px) rotate(-18deg);

    &:before,
    &:after {
      content: "";
      position: absolute;
    }

    &:before {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 2px var(--cart);
      bottom: 0;
      left: 9px;
      filter: drop-shadow(11px 0 0 var(--cart));
    }

    &:after {
      width: 16px;
      height: 9px;
      background: var(--cart);
      left: 9px;
      bottom: 7px;
      transform-origin: 50% 100%;
      transform: perspective(4px) rotateX(-6deg) scaleY(var(--fill, 0));
      transition: transform 1.2s ease var(--fill-d);
    }

    svg {
      z-index: 1;
      width: 36px;
      height: 26px;
      display: block;
      position: relative;
      fill: none;
      stroke: var(--cart);
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;

      polyline {
        &:last-child {
          stroke: var(--tick);
          stroke-dasharray: 10px;
          stroke-dashoffset: var(--offset, 10px);
          transition: stroke-dashoffset 0.4s ease var(--offset-d);
        }
      }
    }
  }

  &.loading {
    --scale: 0.95;
    --span-y: -32px;
    --icon-r: 180deg;
    --fill: 1;
    --fill-d: 0.8s;
    --offset: 0;
    --offset-d: 1.73s;
    background-color: #4ecdc4;

    .cart {
      animation: cart 3.4s linear forwards 0.2s;
    }
  }
}

@keyframes cart {
  12.5% {
    transform: translateX(-60px) rotate(-18deg);
  }
  25%,
  45%,
  55%,
  75% {
    transform: none;
  }
  50% {
    transform: scale(0.9);
  }
  44%,
  56% {
    transform-origin: 12px 23px;
  }
  45%,
  55% {
    transform-origin: 50% 50%;
  }
  87.5% {
    transform: translateX(70px) rotate(-18deg);
  }
  100% {
    transform: translateX(140px) rotate(-18deg);
  }
}
section {
  padding: 0 60px;
}
.card {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.image-container {
  position: relative;
  margin: auto;

  width: 100%;
}

.thumbnail-image {
  border-radius: 10px !important;
  height: 300px;
  object-fit: cover;

  width: 100%;
}

.discount {
  background-color: var(--background);
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 10px;
  border-radius: 6px;
  color: #fff;
}

.wishlist {
  height: 25px;
  width: 25px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.first {
  position: absolute;
  width: 100%;
  padding: 9px;
}

.dress-name {
  font-size: 13px;
  font-weight: bold;
  width: 75%;
}

.new-price {
  font-size: 13px;
  font-weight: bold;
  color: #4ecdc4;
  width: max-content;
}
.old-price {
  font-size: 8px;
  font-weight: bold;
  color: grey;
}

.btn {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  padding: 3px;
}

.creme {
  background-color: #fff;
  border: 2px solid grey;
}
.creme:hover {
  border: 3px solid grey;
}

.creme:focus {
  background-color: grey;
}

.red {
  background-color: #fff;
  border: 2px solid red;
}

.red:hover {
  border: 3px solid red;
}
.red:focus {
  background-color: red;
}

.blue {
  background-color: #fff;
  border: 2px solid #40c4ff;
}

.blue:hover {
  border: 3px solid #40c4ff;
}
.blue:focus {
  background-color: #40c4ff;
}
.darkblue {
  background-color: #fff;
  border: 2px solid #01579b;
}
.darkblue:hover {
  border: 3px solid #01579b;
}
.darkblue:focus {
  background-color: #01579b;
}
.yellow {
  background-color: #fff;
  border: 2px solid #ffca28;
}
.yellow:hover {
  border-radius: 3px solid #ffca28;
}
.yellow:focus {
  background-color: #ffca28;
}

.item-size {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid grey;
  color: grey;
  font-size: 10px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.rating-star {
  font-size: 10px !important;
}
.rating-number {
  font-size: 10px;
  color: grey;
}
.buy {
  font-size: 12px;
  color: purple;
  font-weight: 500;
}

.voutchers {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
}
.voutcher-divider {
  display: flex;
}
.voutcher-left {
  width: 60%;
  cursor: pointer;
}
.voutcher-name {
  color: grey;
  font-size: 9px;
  font-weight: 500;
}
.voutcher-code {
  color: var(--background);
  font-size: 11px;
  font-weight: bold;
}
.voutcher-right {
  background-color: #4ecdc4;
  color: #fff;
  width: 100%;
}

.discount-percent {
  font-size: 12px;
  font-weight: bold;
  position: relative;
  top: 5px;
}
.off {
  font-size: 14px;
  position: relative;
  bottom: 5px;
}

/*------------------------------------*\
    RESET
\*------------------------------------*/
/* https://meyerweb.com/eric/tools/css/reset/
    v2.0b1 | 201101
    NOTE:WORK IN PROGRESS
    USE WITH CAUTION AND TEST WITH ABANDON */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/*------------------------------------*\
    $MAIN
\*------------------------------------*/

/* Product Layout */

.product-filter {
  display: flex;
  padding: 30px 0;
}

.sort {
  display: flex;
  align-self: flex-end;
}

.collection-sort {
  display: flex;
  flex-direction: column;
}

.collection-sort:first-child {
  padding-right: 20px;
}

label {
  color: #666;
  font-size: 10px;
  font-weight: 500;
  line-height: 2em;
  text-transform: uppercase;
}

.products {
  display: flex;
  flex-wrap: wrap;
}

.product-card {
  display: flex;
  flex-direction: column;

  padding: 2%;
  flex-basis: 25%;
}

.product-image img {
  width: 100%;
}

.product-info {
  margin-top: auto;
  padding-top: 20px;
  text-align: center;
}

@media (max-width: 920px) {
  .product-card {
    flex: 1 21%;
  }

  .products .product-card:first-child,
  .products .product-card:nth-child(2) {
    flex: 2 46%;
  }
}

@media (max-width: 600px) {
  .product-card {
    flex: 1 46%;
  }
}

@media (max-width: 480px) {
  h1 {
    margin-bottom: 20px;
  }

  .product-filter {
    flex-direction: column;
  }

  .sort {
    align-self: flex-start;
  }
}
.is-mobile {
  .products-container {
    margin: auto;
  }
  .products {
    padding: 0;
  }
}
</style>
