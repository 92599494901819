export class ConfigCategory {
  constructor(name, des, options, selectedOptionId) {
    this.name = name;
    this.des = des;
    this.options = options;
    this.selectedOptionId = selectedOptionId;
    this.selectedOption = options.find((x) => x.id === selectedOptionId);
    this.reloadPriceDisplay();
  }
  reloadPriceDisplay = () => {
    const currentPrice = this.selectedOption.price;
    this.options.forEach((x) => {
      x.priceDisplay = x.price - currentPrice;
    });
  };
}
