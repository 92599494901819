import { createWebHistory, createRouter } from "vue-router";
import AiDashboard from "@/components/admin/AiDashboard";
import Shop from "@/components/ShoppingSection";
import AiProductDemo from "@/components/ai-products-show-case/AiProductDemo";

const routes = [
  {
    path: "/",
    name: "shop",
    component: Shop,

    meta: { transition: "slide" },
  },
  {
    path: "/admin",
    name: "admin",
    component: AiDashboard,

    meta: { transition: "slideback" },
  },
  {
    path: "/admin-dashboard",
    name: "AiDashboard",
    component: AiDashboard,

    meta: { transition: "slideback" },
  },
  {
    path: "/frag-box",
    name: "AiProductDemo",
    component: AiProductDemo,

    meta: { transition: "slideback" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
