export class ConfigOption {
  constructor(id, text, price) {
    this.id = id;
    this.text = text;
    this.price = price;
    this.priceDisplay = 0;
  }
  onClickOption = () => {
    console.log(this.text);
  };
}
